import React from "react";
import { toLowerCase } from "@/helpers/toLowerCase";
import {
  ActiveSpan,
  BrokenSpan,
  InCompleteSpan,
  SyncingSpan,
  CircleCompleteSpan,
  CircleIncompleteSpan,
  CircleBrokenSpan,
  MainContainer,
} from "./styles";
import { ConnectorStatus } from "@/constants/injestion";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import style from "./source.module.css";

const SyncStatusBadge: React.FC<{ status: string }> = ({ status }) => {
  return (
    <MainContainer>
      {status === ConnectorStatus.ACTIVE ? (
        <ActiveSpan>
          {" "}
          <CircleCompleteSpan></CircleCompleteSpan>
          <ActiveSpan> {toLowerCase(status)}</ActiveSpan>
        </ActiveSpan>
      ) : status === ConnectorStatus.BROKEN ? (
        <BrokenSpan>
          <CircleBrokenSpan></CircleBrokenSpan>
          <BrokenSpan>{toLowerCase(status)}</BrokenSpan>
        </BrokenSpan>
      ) : status === ConnectorStatus.SYNCING ? (
        <SyncingSpan>
          <Spin
            indicator={<LoadingOutlined className={style.IconStyle} spin />}
          />
          <SyncingSpan> {toLowerCase(status)}</SyncingSpan>
        </SyncingSpan>
      ) : (
        <InCompleteSpan>
          <CircleIncompleteSpan></CircleIncompleteSpan>{" "}
          <InCompleteSpan> {toLowerCase(status)}</InCompleteSpan>
        </InCompleteSpan>
      )}
    </MainContainer>
  );
};

export default SyncStatusBadge;
