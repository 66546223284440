import styled from "styled-components";
import theme from "../../../styles/theme";

export const NaviagationsWrapper = styled("div")<{
  active: boolean;
  enabled: boolean;
}>(({ active, enabled }) => ({
  gap: "16px",
  padding: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  backgroundColor: active ? theme.primary800 : "transparent",
  borderRadius: "8px",
  cursor: "pointer",
  opacity: enabled ? 1 : 0.5,
  margin: "4px 0",
  "&:hover": {
    backgroundColor: !active ? theme.neutral700 : theme.primary900,
  },
}));
