import { teamsIcons } from "../icons/teams";

const { greenCheck, redCross, readOnly } = teamsIcons;

type AccessContent = {
  [key: string]: { label: string; icon: string };
};

export const ROLE_ACCESS = {
  FULL_ACCESS: "FULL_ACCESS",
  READ_ONLY: "READ_ONLY",
  NO_ACCESS: "NO_ACCESS",
};

export const accessContent: AccessContent = {
  FULL_ACCESS: { label: "Full access", icon: greenCheck },
  READ_ONLY: { label: "Read Only", icon: readOnly },
  NO_ACCESS: { label: "No Access", icon: redCross },
};
