import { useRouter } from "next/router";
import React from "react";
import styled from "styled-components";
import { ROUTES } from "../constants/routes";
import { Flex } from "@/styles/styled/styled";
import theme from "@/styles/theme";
import { routeNames } from "@/constants/routes/routeNames";
import Sidebar from "@/components/onboarding/sidebar";
import NextImage from "@/uiCore/image";
import { onboardingIcons } from "@/constants/icons/onboarding";

type OnboardingLayoutProps = {
  children: React.ReactNode;
  layoutProps?: any;
};

const LayoutWrapper = styled("div")<{
  isSignup: boolean;
  isSidePanel: boolean;
}>(({ isSidePanel }) => ({
  height: "100vh",
  width: isSidePanel ? "calc(100vw - 500px)" : "100vw",
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const MainChild = styled("div")(() => ({
  zIndex: 1000,
}));

const SideWrapper = styled("div")(() => ({
  height: "100vh",
  width: "500px",
  overflow: "hidden",
  zIndex: 1000,
  background: theme.textWhite,
}));

const renderLayout = (isSignup: boolean, children: any, isSidePanel: any) => {
  return (
    <LayoutWrapper isSignup={isSignup} isSidePanel={isSidePanel}>
      <NextImage
        src={
          onboardingIcons[
            isSignup ? "signUpBackground" : "onboarding-background"
          ]
        }
        alt="bg-image"
        layout="fill"
      />
      <MainChild>{children}</MainChild>
    </LayoutWrapper>
  );
};
export const OnboardingLayout: React.FC<OnboardingLayoutProps> = ({
  children,
  layoutProps,
}) => {
  const { connectWarehouse } = routeNames;
  const router = useRouter();
  const isSignup =
    router.pathname === ROUTES.Signup.path ||
    router.pathname === ROUTES.Home.path ||
    router.pathname === ROUTES.VerifyEmail.path;
  return router.pathname.endsWith(connectWarehouse) ? (
    <Flex wrap="nowrap" justifyContent="flex-start">
      {renderLayout(
        isSignup,
        children,
        router.pathname.endsWith(connectWarehouse),
      )}
      <SideWrapper>
        <Sidebar sidebar={layoutProps} />
      </SideWrapper>
    </Flex>
  ) : (
    renderLayout(isSignup, children, router.pathname.endsWith(connectWarehouse))
  );
};
