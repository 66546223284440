import { createSlice } from "@reduxjs/toolkit";

export const injestionStore = createSlice({
  name: "injestion",
  initialState: {
    connectorType: "",
    destination: "",
    config: {},
    addConnector: false,
    connectorTypes: [],
    destinations: [],
    updateConnectorList: false,
    connectorNotification: {},
    connectorLength: 0,
    connectorList: [],
    schemaChanged: false,
    settingsChanged: false,
    connectorDetails: {},
    showSelectedTables: false,
    reloadSchemas: false,
    hashedData: [],
    isSavingChanges: false,
  },
  reducers: {
    setConnectorTypes: (state, action) => {
      if ("connectorTypes" in action.payload) {
        state.connectorTypes = action.payload;
      }
    },
    setDestination: (state, action) => {
      if ("destinations" in action.payload) {
        state.destinations = action.payload;
      }
    },
    setAddConnector: (state, action) => {
      if ("connectorType" in action.payload) {
        state.connectorType = action.payload;
      }
      if ("destination" in action.payload) {
        state.destination = action.payload;
      }
      if ("config" in action.payload) {
        state.config = action.payload;
      }
      if ("addConnector" in action.payload) {
        state.addConnector = action.payload;
      }
    },
    setUpdateConnector: (state, action) => {
      if ("updateConnectorList" in action.payload) {
        state.updateConnectorList = action.payload;
      }
      if ("connectorNotification" in action.payload) {
        state.connectorNotification = action.payload;
      }
      if ("connectorList" in action.payload) {
        state.connectorList = action.payload;
      }
      if ("schemaChanged" in action.payload) {
        state.schemaChanged = action.payload;
      }
      if ("settingsChanged" in action.payload) {
        state.settingsChanged = action.payload;
      }
    },
    setConnectorLength: (state, action) => {
      if ("connectorLength" in action.payload) {
        state.connectorLength = action.payload;
      }
    },
    setConnectorDetails: (state, action) => {
      if ("connectorDetails" in action.payload) {
        state.connectorDetails = action.payload;
      }
    },
    setSchemaData: (state, action) => {
      if ("showSelectedTables" in action.payload) {
        state.showSelectedTables = action.payload;
      }
      if ("reloadSchemas" in action.payload) {
        state.reloadSchemas = action.payload;
      }
      if ("hashedData" in action.payload) {
        state.hashedData = action.payload;
      }
      if ("isSavingChanges" in action.payload) {
        state.isSavingChanges = action.payload;
      }
    },
  },
});

export const {
  setAddConnector,
  setConnectorTypes,
  setDestination,
  setUpdateConnector,
  setConnectorLength,
  setConnectorDetails,
  setSchemaData,
} = injestionStore.actions;

export default injestionStore.reducer;
