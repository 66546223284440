import { createSlice } from "@reduxjs/toolkit";

export const dbtDocsStore = createSlice({
  name: "dbtDocs",
  initialState: {
    repoData: [],
  },
  reducers: {
    setDbtDocsRepoData: (state, action) => {
      if ("repoData" in action.payload) {
        state.repoData = action.payload;
      }
    },
  },
});

export const { setDbtDocsRepoData } = dbtDocsStore.actions;

export default dbtDocsStore.reducer;
