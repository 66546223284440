export const ConnectorStatus = {
  INCOMPLETE: "INCOMPLETE",
  PAUSED: "PAUSED",
  ACTIVE: "ACTIVE",
  BROKEN: "BROKEN",
  SYNCING: "SYNCING",
};

export const SchemaActions = {
  HASHED: "Hashed",
  UNHASHED: "Unhashed",
  BLOCKED: "Blocked",
  UNBLOCKED: "Unblocked",
};

export const SchemaActionsText = {
  HASHED: "Hash",
  UNHASHED: "Unhash",
  BLOCKED: "Block",
  UNBLOCKED: "Unblock",
};

export const UnBlockModalData = [
  {
    operationType: "HASHED",
    header: "Altering these columns in the next sync!",
    body: "Existing data remains unaffected, necessitating a full resync to update historical data and possibly causing sync delays.",
    noreSync:
      "All newly synced records will have hashed values in your destination.",
    resync: "The re-sync may impact your paid MAR",
  },
  {
    operationType: "UNHASHED",
    header: "We will not hash these columns going forward.",
    body: "Existing records will have hashed values in these columns. To overwrite the hashed values in existing records, you must re-sync your tables.",
    noreSync: "Existing records will have hashed values in these columns.",
    resync: "The re-sync may impact your paid MAR",
  },
  {
    operationType: "UNBLOCKED",
    header: "Replicating these columns in the next sync!",
    body: "We will populate these columns from now on; existing records will have NULL values. A full connector re-sync is needed to update historical data.",
    noreSync: "Existing records will have NULL values in these columns.",
    resync: "The re-sync may impact your paid MAR",
  },
  {
    operationType: "MULTIPLE",
    header: "Altering these columns in the next sync!",
    body: "We will alter these columns from now on; previously synced records will remain unaffected. A full connector re-sync is needed to update historical data.",
    noreSync: "Newly synced data will be hashed/blocked/unblocked",
    resync: "The re-sync may impact your paid MAR",
  },
];

export const BlockModalData = {
  operationType: "BLOCKED",
  header: "Altering these columns in the next sync!",
  body1:
    "You've chosen to block these columns, so all newly synced records will have null values in your destination and previously synced data will remain unaffected. Alternatively, you can drop the columns.",
  body2:
    "Note: Connector syncs will be paused during destination updates, which may cause data delays.",
  description: "Queries that use these columns will break.",
};
