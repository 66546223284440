import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { ROUTES } from "../constants/routes";
import { MainLayout } from "./mainLayout";
import { OnboardingLayout } from "./onboardingLayout";
import { checkStackStatus } from "../helpers/validateStackStatus";
import { routeNames } from "@/constants/routes/routeNames";
import { setRedirectUrl } from "@/helpers/cookieStorage";

type DefaultLayoutProps = {
  children: React.ReactNode;
  layoutProps: any;
};

export const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children,
  layoutProps,
}) => {
  const router = useRouter();
  const [layout, setLayout] = useState<string | undefined>();
  const [isPageHeader, setIsPageHeader] = useState<boolean>(false);
  const [isBackButton, setIsBackButton] = useState<boolean>(false);
  const [iseSettingsLayout, setIsSettingsLayout] = useState<boolean>(false);
  const [headerTitle, setHeaderTitle] = useState<string>("");
  useEffect(() => {
    for (const key in ROUTES) {
      if (Object.prototype.hasOwnProperty.call(ROUTES, key)) {
        if (
          router.pathname === ROUTES[key].path ||
          router.pathname === routeNames.joinWorkspace
        ) {
          if (router.pathname === routeNames.joinWorkspace) {
            setLayout("onboarding");
          } else setLayout(ROUTES[key].layout);
          setIsPageHeader(ROUTES[key].pageHeader);
          setIsBackButton(ROUTES[key].isBackButton || false);
          setHeaderTitle(ROUTES[key].name);
          setIsSettingsLayout(ROUTES[key].isSettingsLayout || false);
          break;
        }
      }
    }
  }, [router]);

  useEffect(() => {
    if (window.location.href.includes("directLink")) {
      setRedirectUrl(window.location.href.split("&")[0]);
    }
    const fetchData = async () => {
      for (const key in ROUTES) {
        if (Object.prototype.hasOwnProperty.call(ROUTES, key)) {
          if (
            router.pathname === ROUTES[key].path &&
            ROUTES[key].isAuthenticatedRoute
          ) {
            const route = await checkStackStatus();
            if (route) {
              router.push(route);
            }
            break;
          }
        }
      }
    };
    fetchData();
  }, []);

  const getLayout = () => {
    if (!layout) {
      return;
    }
    switch (layout) {
      case "main":
        return <MainLayout isBackButton={isBackButton}>{children}</MainLayout>;
      case "onboarding":
        return (
          <OnboardingLayout layoutProps={layoutProps}>
            {children}
          </OnboardingLayout>
        );
      case "settings":
        return (
          <MainLayout
            pageHeader={isPageHeader}
            isBackButton={isBackButton}
            isSettingsLayout={iseSettingsLayout}
            headerTitle={headerTitle}
          >
            {children}
          </MainLayout>
        );
      default:
        return (
          <MainLayout
            pageHeader={isPageHeader}
            isBackButton={isBackButton}
            isSettingsLayout={iseSettingsLayout}
            headerTitle={headerTitle}
          >
            {children}
          </MainLayout>
        );
    }
  };

  return <div>{getLayout()}</div>;
};
