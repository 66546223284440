export const IngestionIcons: { [key: string]: string } = {
  caretDown: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/CaretDown.svg`,
  checkBlack: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/CheckBlack.svg`,
  connectorPlug: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/connector-plug.svg`,
  delete_head: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/delete_head.svg`,
  edit: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/Edit-setup.svg`,
  initialSync: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/initial-sync.svg`,
  info_head: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/info_head.svg`,
  pause: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/Pause.svg`,
  plugBlack: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/plug-black.svg`,
  primaryKey: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/Key.svg`,
  reSync: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/Re-sync.svg`,
  resume: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/Play.svg`,
  sync: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/Sync.svg`,
  tableAction: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/tableAction.svg`,
  trash: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/Trash.svg`,
  pauseInactive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/PauseInactive.svg`,
  resumeInactive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/PlayInactive.svg`,
  syncInactive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/SyncInactive.svg`,
  reSyncInactive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ingestion/ReSyncInactive.svg`,
};
