import React, { useState, useEffect } from "react";
import { MessageWrapper } from "./styled";
import Typography from "../typography";
import { getTextColor } from "./helpers";
import NextImage from "../image";
import { commonIcons } from "@/constants/icons/common";

export const useNotification = () => {
  const [notification, setNotification] = useState<{
    status: string;
    message: string;
  } | null>(null);

  const showNotification = (status: string, message: string) => {
    setNotification({ status, message });
    setTimeout(() => {
      setNotification(null);
    }, 3000); // Close the notification and clear the data after 3 seconds
  };

  return {
    showNotification,
    notification,
  };
};

type MessageProps = {
  status?: "info" | "success" | "warning" | "error" | undefined;
  message: string;
};

const Notification: React.FC<MessageProps> = ({ status, message }) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (status && message) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
      }, 3000); // Hide notification after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [status, message]);

  return (
    visible && (
      <MessageWrapper status={status || "info"}>
        <NextImage
          src={commonIcons[status as keyof typeof commonIcons]}
          alt={"messageStatus"}
          width={20}
          height={20}
        />
        <Typography.P2 color={getTextColor(status as string)}>
          {message?.substring(0, 100) + (message?.length > 100 ? "..." : "")}
        </Typography.P2>
      </MessageWrapper>
    )
  );
};

export const Message: React.FC<MessageProps> = ({ status, message }) => {
  const { showNotification, notification } = useNotification();

  useEffect(() => {
    showNotification(status as any, message);
  }, [status, message]);

  return (
    <div>
      {notification && (
        <Notification
          status={
            notification.status as
              | "info"
              | "success"
              | "warning"
              | "error"
              | undefined
          }
          message={notification.message}
        />
      )}
    </div>
  );
};
