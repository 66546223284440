import styled from "styled-components";
import { Flex } from "@/styles/styled/styled";
import theme from "@/styles/theme";

export const TrialdaysWrapper = styled(Flex)(() => ({
  padding: "8px",
  background: theme.neutral800,
  color: theme.textWhite,
  borderRadius: "8px",
  marginTop: "16px",
  wrap: "nowrap",
  cursor: "pointer",
}));
