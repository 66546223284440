import { navigationIcons } from "@/constants/icons/navigations";
import styled from "styled-components";
import Typography from "@/uiCore/typography";

export const BackgroundImage = styled("div")(() => ({
  background: `url(${navigationIcons.trial})`,
  position: "fixed",
  width: "100%",
  height: "100vh",
  backgroundSize: "cover",
  backgroundPosition: "center",
  zIndex: 1,
  top: 0,
  left: 0,
}));

export const BackdropWrapper = styled("div")(() => ({
  position: "fixed",
  width: "100%",
  height: "100vh",
  background: "rgba(0, 0, 0, 0.5)",
  backdropFilter: "blur(5px)",
  zIndex: 0,
  top: 0,
  left: 0,
}));

export const FooterButtonWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  width: "95%",
  gap: "10px",
  marginTop: "16px",
  marginLeft: "5px",
  marginBottom: "10px",
}));

export const PlanContainer = styled("div")(() => ({
  background: "var(--Neutral-50)",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  cursor: "pointer",
  paddingBottom: "8px",
}));

export const PlanTitle = styled("div")(() => ({
  color: "var(--Text-Primary)",

  /* Semibold/Paragraph 2 */
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "120%",
  letterSpacing: "-0.28px",
  justifyContent: "flex-start",
  alignItems: "flex-start",
}));

export const PlanSubtitle = styled("div")(() => ({
  color: "var(--Text-Primary)",

  /* Regular/Paragraph 3 */
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "120%",
  letterSpacing: "-0.24px",
  padding: "5px 26px",
}));

export const PlanIcon = styled("span")(() => ({
  marginRight: "8px",
}));

export const StorageHeading = styled(Typography.P3)(() => ({
  padding: "8px",
}));
