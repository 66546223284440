import styled from "styled-components";
import theme from "../../styles/theme";

type TypographyProp = {
  color?: string;
  weight?: number;
  fontStyle?: string;
  margin?: string;
  lineHeight?: number;
};

const baseStyles = styled.div<TypographyProp>(
  ({ color, weight, fontStyle, margin, lineHeight }) => ({
    color: color || theme.textPrimary,
    margin: margin ? margin : 0,
    fontWeight: weight || theme.fontWeightRegular,
    letterSpacing: theme.letterSpacing,
    fontStyle,
    lineHeight: lineHeight ? `${lineHeight}px` : 1.1,
  }),
);

export const H1 = styled(baseStyles)(() => ({
  fontSize: theme.fontH1,
}));

export const H2 = styled(baseStyles)(() => ({
  fontSize: theme.fontH2,
}));

export const H3 = styled(baseStyles)(() => ({
  fontSize: theme.fontH3,
}));

export const P1 = styled(baseStyles)(() => ({
  fontSize: theme.fontP1,
}));

export const P2 = styled(baseStyles)(() => ({
  fontSize: theme.fontP2,
}));

export const P3 = styled(baseStyles)(() => ({
  fontSize: theme.fontP3,
}));

export const L1 = styled(baseStyles)(() => ({
  fontSize: theme.fontL1,
}));

export const L2 = styled(baseStyles)(() => ({
  fontSize: theme.fontL2,
}));
