import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import NavbarHeader from "./navHeader";
import {
  NavigationsBottom,
  NavigationsMiddle,
  NavigationsTop,
} from "../../constants/navigation";
import Navigations from "./navigations";
import { NavbarWrapper } from "./styled";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setNavigation } from "../../redux/userSlice";
import TrialComponent from "./trial";
import { settingsMenu } from "@/constants/settings";
import { ROLE_ACCESS } from "@/constants/rbac";

const Navbar: React.FC = () => {
  const dispatch = useDispatch();
  const handleHover = (value: boolean) => {
    dispatch(setNavigation({ navbarHovered: value }));
  };
  const [updatedNavigationsBottom, setUpdatedNavigationsBottom] =
    useState(NavigationsBottom);
  const [updatedNavigationsTop, setUpdatedNavigationsTop] =
    useState(NavigationsTop);

  const companyDetails =
    useSelector((state: { user: any }) => state.user.companyDetails)
      ?.companyDetails || {};

  const showDocsOnNavbar = useSelector(
    (state: { user: any }) => state.user.showDocsOnNavbar,
  ).showDocsOnNavbar;

  const { entitlements } = companyDetails || {};

  useEffect(() => {
    if (showDocsOnNavbar) {
      setUpdatedNavigationsTop((prev: any) => {
        return [
          ...prev,
          {
            name: "Docs",
            icon: "dbtDocWhite",
            redirectTo: "/docs",
            enabled: true,
            id: "dbtDocs",
          },
        ];
      });
    } else {
      setUpdatedNavigationsTop(NavigationsTop);
    }
  }, [showDocsOnNavbar]);

  useEffect(() => {
    if (!entitlements) return;
    const route = settingsMenu?.find((item) => {
      return (
        entitlements?.[`${item?.id?.toUpperCase()}`] !== ROLE_ACCESS.NO_ACCESS
      );
    });
    setUpdatedNavigationsBottom((prev: any) => {
      return [
        {
          name: "Settings",
          icon: "settingsNew",
          redirectTo: route?.path,
          enabled: true,
          id: "settings",
        },
        ...prev.slice(1),
      ];
    });
  }, [entitlements]);

  return (
    <NavbarWrapper
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <div>
        <NavbarHeader />
        <div className={styles.divider}>
          <Divider />
        </div>
        <Navigations navigationData={updatedNavigationsTop as any} />
        <div className={styles.divider}>
          <Divider />
        </div>
        <Navigations navigationData={NavigationsMiddle as any} />
        <TrialComponent />
      </div>
      <div>
        <Navigations navigationData={updatedNavigationsBottom as any} />
      </div>
    </NavbarWrapper>
  );
};

export default Navbar;
