import theme from "../../styles/theme";

export const getTextColor = (status: string) => {
  if (status === "info") {
    return theme.textPrimary;
  } else if (status === "success") {
    return theme.success700;
  } else if (status === "error") {
    return theme.error700;
  } else if (status === "warning") {
    return theme.warning700;
  }
};

export const getBorderColor = (status: string) => {
  if (status === "info") {
    return theme.neutral600;
  } else if (status === "success") {
    return theme.success600;
  } else if (status === "error") {
    return theme.error600;
  } else if (status === "warning") {
    return theme.warning600;
  }
};
