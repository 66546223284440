import React from "react";
import { H1, H2, H3, P1, P2, P3, L1, L2 } from "./styled";

const Typography = {
  H1: (props: any) => <H1 {...props}>{props.children}</H1>, // Font size: 31.25px
  H2: (props: any) => <H2 {...props}>{props.children}</H2>, // Font size: 25px
  H3: (props: any) => <H3 {...props}>{props.children}</H3>, // Font size: 20px
  P1: (props: any) => <P1 {...props}>{props.children}</P1>, // Font size: 16px
  P2: (props: any) => <P2 {...props}>{props.children}</P2>, // Font size: 14px
  P3: (props: any) => <P3 {...props}>{props.children}</P3>, // Font size: 12px
  L1: (props: any) => <L1 {...props}>{props.children}</L1>, // Font size: 10px
  L2: (props: any) => <L2 {...props}>{props.children}</L2>, // Font size: 8px
};

export default Typography;
