import { commonIcons } from "@/constants/icons/common";
import theme from "@/styles/theme";

export const encodedString = (email: string) =>
  encodeURIComponent(email)
    ?.replace(/(%20| )/g, "+")
    .replace(/%40/g, "@");

export const PaymentStatus: any = {
  FAILED: {
    label: "Failed",
    color: theme.error600,
    icon: commonIcons.dotError600,
  },
  OVERDUE: {
    label: "Overdue",
    color: theme.error600,
    icon: commonIcons.dotError600,
  },
  PAID: {
    label: "Paid",
    color: theme.success600,
    icon: commonIcons.dotSuccess600,
  },
  SUCCESS: {
    label: "Paid",
    color: theme.success600,
    icon: commonIcons.dotSuccess600,
  },
  PENDING: {
    label: "Pending",
    color: theme.warning600,
    icon: commonIcons.dotWarning600,
  },
};

export const windowsScrollbarStyle = () => {
  function isWindows() {
    return navigator.platform.indexOf("Win") > -1;
  }

  if (isWindows()) {
    const style = document.createElement("style");
    style.innerHTML = `
        ::-webkit-scrollbar {
            width: 8px;
        }
        ::-webkit-scrollbar-track {
            background: #fff;
        }
        ::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 6px;
        }
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    `;
    document.head.appendChild(style);
  }
};

export const capitalizeFirstLetter = (string: string) => {
  if (!string) return string; // Handle empty strings
  return string.charAt(0).toUpperCase() + string.slice(1);
};
