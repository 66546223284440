export const calculateTotalDays = (startDate: string, endDate: string) => {
  const start: Date = new Date(startDate);
  const end: Date = new Date(endDate);
  const difference = end.getTime() - start.getTime(); // Difference in milliseconds
  const totalDays = difference / (1000 * 60 * 60 * 24); // Convert milliseconds to days
  return totalDays;
};

export const getPercentage = (
  startDate: string,
  endDate: string,
  daysLeft: number,
) => {
  const totalDays = calculateTotalDays(startDate, endDate);
  if (totalDays === 0) {
    // Check to prevent division by zero
    return 0; // Optionally handle this case differently based on your requirements
  }
  const percentage = (daysLeft / totalDays) * 100;
  return percentage.toFixed(2); // Rounds the result to two decimal places for better readability
};
