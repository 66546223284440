import theme from "@/styles/theme";
import Typography from "@/uiCore/typography";
import styled from "styled-components";

export const HeaderWrapper = styled("div")(() => ({
  padding: "32px",
  borderBottom: "1px solid #E5E5E5",
}));

export const ContentWrapper = styled("div")(() => ({
  padding: "32px",
  height: "calc(100vh - 118px)",
  overflowY: "auto",
}));

export const Description = styled(Typography.P2)(() => ({
  lineHeight: "19.6px",
}));

export const ScriptWrapper = styled("div")(() => ({
  background: theme.neutral100,
  padding: "16px",
  overflow: "auto",
  position: "relative",
  borderRadius: "6px",
  width: "100%",
}));

export const CopyIcon = styled("div")(() => ({
  height: "32px",
  width: "32px",
  background: theme.textWhite,
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: `1px solid ${theme.neutral300}`,
  borderRadius: "4px",
  position: "absolute",
  top: "16px",
  right: "16px",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
}));
