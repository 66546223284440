import { useEffect, useState } from "react";

const useSize = () => {
  const [windowSize, setWindowSize] = useState([
    typeof window !== "undefined" ? window.innerWidth : 0,
    typeof window !== "undefined" ? window.innerHeight : 0,
  ]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const windowSizeHandler = () => {
        setWindowSize([window.innerWidth, window.innerHeight]);
      };
      window.addEventListener("resize", windowSizeHandler);
      return () => {
        window.removeEventListener("resize", windowSizeHandler);
      };
    }
  }, []);

  return windowSize;
};

export default useSize;
