import { createSlice } from "@reduxjs/toolkit";

export const trialStore = createSlice({
  name: "trial",
  initialState: {
    title: "",
    closable: false,
    trialActive: false,
    plans: null,
    trialExpired: false,
  },
  reducers: {
    setTrial: (state, action) => {
      if ("title" in action.payload) {
        state.title = action.payload.title;
      }
      if ("closable" in action.payload) {
        state.closable = action.payload.closable;
      }
      if ("trialActive" in action.payload) {
        state.trialActive = action.payload.trialActive;
      }
      if ("plans" in action.payload) {
        state.plans = action.payload.plans;
      }
      if ("trialExpired" in action.payload) {
        state.trialExpired = action.payload.trialExpired;
      }
    },
  },
});

export const { setTrial } = trialStore.actions;

export default trialStore.reducer;
