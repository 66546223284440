import { commonIcons } from "../icons/common";
import { onboardingIcons } from "../icons/onboarding";
const {
  author,
  steveHibberd,
  georgeFerreira,
  snowflake,
  novo,
  topShelfInternational,
  monicaVinader,
  amalan,
  cupbop,
  xap,
  tripScout,
  launchMetrics,
} = onboardingIcons || {};

const { FivexLogoWhite } = commonIcons || {};

export const SIGN_UP = {
  imageURL: FivexLogoWhite,
  title: "Create a free account",
  subTitle: "True trial. No credit card or sales call required.",
};

export const VERIFY_CODE = {
  title: "You’re almost there!",
  subTitle: "We’ve sent you a 4 digit code on your mail",
  changeEmailText: "Change Email",
  resendText: [
    {
      text: "Didn't receive the code?",
      isLink: false,
    },
    { text: "Resend code", isLink: true },
  ],
  policyText: [
    {
      text: "By continuing, you agree to our",
      isLink: false,
    },
    {
      text: "Terms of Service",
      link: "https://www.5x.co/terms-of-service",
      isLink: true,
    },
    {
      text: "and acknowledge our",
      isLink: false,
    },
    {
      text: "Privacy Policy",
      link: "https://www.5x.co/privacy-policy",
      isLink: true,
    },
  ],
};

export const LOGOS = [
  {
    url: novo,
    alt: "novo Logo",
    width: 66,
    height: 15,
  },
  {
    url: topShelfInternational,
    alt: "topShelf Logo",

    width: 78,
    height: 16,
  },
  {
    url: monicaVinader,
    alt: "monicaVinader Logo",
    width: 77.24,
    height: 25.98,
  },
  {
    url: amalan,
    alt: "amalan Logo",
    width: 42.87,
    height: 17,
  },
  {
    url: cupbop,
    alt: "cupbop",
    width: 65,
    height: 11.66,
  },
  {
    url: xap,
    alt: "xap",
    width: 28.69,
    height: 29.19,
  },
  {
    url: tripScout,
    alt: "tripScout",
    width: 66,
    height: 12.65,
  },
  {
    url: launchMetrics,
    alt: "launchMetrics",
    width: 42,
    height: 16.15,
  },
];

export const CAROUSEL_DATA = [
  {
    id: 1,
    imageURL: georgeFerreira,
    designation: "CTO, Upright",
    name: "George Ferreira",
    message:
      // eslint-disable-next-line quotes
      '"5X\'s data platform provided the capabilities we needed out-of-the-box. No resources or time wasted in stitching a platform together with multiple vendors."',
  },
  {
    id: 2,
    imageURL: author,
    designation: "Head of Data, Novo",
    name: "Anthony M. Jerkovic",
    message:
      "\"5X's full-stack approach means we don't have to worry about stitching components or think through every possible edge case in advance.\"",
  },
  {
    id: 3,
    imageURL: steveHibberd,
    designation: "Data & Strategy Lead, Top Shelf",
    name: "Steve Hibberd",
    message:
      // eslint-disable-next-line quotes
      '"Ever since we started using 5X, working with data has become a breeze. With their integrated data consultancy, it feels like we\'ve got a whole team of data experts rallying behind us."',
  },
];

export const VENDOR_SETUP_STATUS = [
  "complete",
  "failed",
  "incomplete",
  "in_progress",
  "not_created",
];

export const NO_DATA = {
  title: "Start Inviting Your Teammates",
  subTitle: "Your workspace team users will appear here.",
};

export const OUTSIDE_FIVEX = {
  title: "Wuhoo! All accounts are on 5X!",
  subTitle: "We’ll notify you if we find any other teammates",
  subTitleLine2: "outside of your 5X workspace",
};

export const SETUP_WORKSPACE = [
  {
    id: 1,
    title: "No, I don't have a warehouse yet",
    description: "5X will provision a new warehouse for you",
    value: "newWarehouse",
  },
  {
    id: 2,
    title: "Yes, I already have an existing warehouse",
    description: "5X will connect to your existing warehouse",
    value: "existingWarehouse",
  },
];

export const WAREHOUSES = [
  {
    id: "snowflake",
    name: "Snowflake",
    value: "snowflake",
    url: snowflake,
  },
  // {
  //   id: "big-query",
  //   name: "Google Big Query",
  //   value: "big-query",
  //   url: bigQuery,
  // },
];
