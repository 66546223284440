import React from "react";
import { Table } from "antd";
import styles from "../index.module.css";
import { Flex } from "@/styles/styled/styled";
import Typography from "@/uiCore/typography";
import theme from "@/styles/theme";
import { capitalizeFirstLetter } from "@/helpers/common";
import { StorageHeading } from "../styled";
import NextImage from "@/uiCore/image";

const StorageTable: React.FC<any> = ({ data, type, biTooltip }) => {
  const columns = [
    {
      title: "Compute Size",
      dataIndex: "rows",
      key: "rows",
      className: styles.customCell,
      width: "220px",
      render: (item: any) => {
        return (
          <Flex justifyContent="flex-start">
            <NextImage src={item.logo} alt="info" width={48} height={48} />
            <Typography.P3 margin="0 0 0 4px" color={theme.textSecondary}>
              {capitalizeFirstLetter(item.size)}
            </Typography.P3>
          </Flex>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: "100px",
      className: styles.customCell,
      render: (item: any) => {
        return type === "BI" ? (
          <div>
            <Typography.P3 margin="0 0 4px 0">{item.price}</Typography.P3>
            <Typography.P3 color={theme.textSecondary}>
              {item.tenure}
            </Typography.P3>
          </div>
        ) : (
          <div>
            <Typography.P3 margin="0 0 4px 0" color={theme.textSecondary}>
              {item.price}
            </Typography.P3>
            <Typography.P3>{item.usageLimit}</Typography.P3>
          </div>
        );
      },
    },
  ];

  const rowsData = data?.map((item: any, index: any) => {
    return {
      key: index,
      rows: item,
      price: item,
    };
  });

  return (
    <div>
      {type === "BI" && biTooltip && (
        <StorageHeading>{biTooltip}</StorageHeading>
      )}

      <Table
        columns={columns}
        dataSource={rowsData}
        pagination={false}
        showHeader={type === "BI" ? false : true}
        bordered={type === "BI" ? false : true}
      />
    </div>
  );
};

export default StorageTable;
