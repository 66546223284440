export const commonIcons: { [key: string]: string } = {
  FivexIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/5X-icon.svg`,
  FivexLogoPrimary: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/5x-logo-primary.svg`,
  FivexLogoWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/5x-logo-white.svg`,
  arrowRight: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/arrow-right.svg`,
  arrowRightWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/ArrowRightWhite.svg`,
  backButton: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/back-btn.svg`,
  caretRight: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/caretRight.svg`,
  checkWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/Check.svg`,
  checkedCheckbox: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/checked-checkbox.svg`,
  close: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/close.svg`,
  closeLight: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/close-light.svg`,
  copy: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/copy.svg`,
  defaultCheckbox: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/default-checkbox.svg`,
  deleteGrey: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/delete-grey.svg`,
  deleteWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/delete-white.svg`,
  drag: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/drag.svg`,
  duplicate: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/duplicate.svg`,
  edit: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/edit.svg`,
  editIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/editIcon.svg`,
  editPencil: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/editPencil.svg`,
  error: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/error.svg`,
  eyeClosed: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/eye-closed.svg`,
  eyeOpen: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/eye-open.svg`,
  filter: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/filter.svg`,
  "filter-applied": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/filter-applied.svg`,
  fiveXLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}5X_Logo.svg`,
  google: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/google.svg`,
  "hide-password": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/hide-password.svg`,
  info: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/info.svg`,
  "login-with-google": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/login-with-google.svg`,
  playPrimary: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/play_primary.svg`,
  plus: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/plus.svg`,
  plusGrey: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/plus_grey.svg`,
  plug: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/plug-icon.svg`,
  plugWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/plug-white.svg`,
  radioChecked: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/radio-checked.svg`,
  redDelete: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/red-delete.svg`,
  save: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/save.svg`,
  "show-password": `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/show-password.svg`,
  success: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/success.svg`,
  successCheck: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/CheckCircle.svg`,
  threeDotMenu: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/three-dot-menu.svg`,
  warning: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/warning.svg`,
  dotError600: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/dot-error-600.svg`,
  dotWarning600: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/dot-warning-600.svg`,
  dotSuccess600: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/dot-success-600.svg`,
  signupWithGoogle: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/SignupWithGoogle.svg`,
  loginWithGoogle: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/LoginWithGoogle.svg`,
  fiveXBiGif: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/5X_Business_Intelligence.gif`,
  unsupportedScreenGif: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/unsupported-animation-2.gif`,
  emailIconWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/email-icon-white.svg`,
  slackIconWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/slack-icon-white.svg`,
  plusBlack: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/plus-black.svg`,
  logout2: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/logout-icon2.svg`,
  logout3: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/logout3.svg`,
  refresh: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/refresh.svg`,
  trashDisable: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/trash-disable.svg`,
  skull: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/skull.svg`,
  warningInfo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/warning-info.svg`,
  play: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}common/play.svg`,
};
