import React, { forwardRef, useImperativeHandle } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

type CheckoutFormProps = {
  setCardStatus?: React.Dispatch<React.SetStateAction<string>>;
};

const CheckoutForm = forwardRef(({ setCardStatus }: CheckoutFormProps, ref) => {
  const stripe = useStripe();
  const elements = useElements();

  useImperativeHandle(ref, () => ({
    submitForm: () => handleSubmit(),
  }));

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
    });

    if (error?.type === "validation_error") {
      return { status: "error", value: error?.message };
    } else if (
      error?.type === "card_error" ||
      error?.type === "invalid_request_error" ||
      error?.code === "card_declined"
    ) {
      if (setCardStatus) setCardStatus("error");
      return { status: "error", value: error?.message };
    } else {
      return { status: "success", value: setupIntent?.payment_method };
    }
  };

  return (
    <form>
      <PaymentElement />
    </form>
  );
});

CheckoutForm.displayName = "CheckoutForm";

export default CheckoutForm;
