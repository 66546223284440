import { createSlice } from "@reduxjs/toolkit";

const updateState = (state: any, payload: any, key: any) => {
  if (key in payload) {
    state[key] = payload;
  }
};

export const teams = createSlice({
  name: "teams",
  initialState: {
    addUser: false,
    userLength: 0,
    editUser: false,
    roles: [],
    inviteUser: false,
    associateUser: false,
    externalUsers: [],
    tab: "1",
    refresh: 1,
    userModified: false,
  },
  reducers: {
    setTeams: (state, action) => {
      const keysToUpdate = [
        "addUser",
        "userLength",
        "editUser",
        "roles",
        "inviteUser",
        "associateUser",
        "externalUsers",
        "tab",
        "refresh",
        "userModified",
        "enableHeaderButton",
      ];

      keysToUpdate.forEach((key) => updateState(state, action.payload, key));
    },
  },
});

export const { setTeams } = teams.actions;

export default teams.reducer;
