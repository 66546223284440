import styled from "styled-components";
import theme from "@/styles/theme";
import { Menu } from "antd";

export const SidebarWrapper = styled.div`
  display: flex;
  width: 200px;
  height: 100vh;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;
  background: #fff;
  .custom-menu .ant-menu-item-selected {
    background-color: ${theme.primary100} !important;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

export const StyledMenu = styled(Menu)`
  border: none !important;
`;

export const ImageWrapper = styled.div`
  display: inline-block;
  margin-right: 8px;
`;
