import theme from "../../styles/theme";

export type SelectSizes = "sm" | "md";

export const getHeight = (size: SelectSizes) => {
  const heightMap = {
    sm: theme.inputSmHeight,
    md: theme.inputMdHeight,
  };

  return heightMap[size] || theme.buttonSmHeight;
};

export const getFontSize = (size: SelectSizes) => {
  const fontSizeMap = {
    sm: theme.fontP2,
    md: theme.fontP3,
  };
  return fontSizeMap[size] || theme.fontP2;
};

export const getBackgroundColor = (inactive: boolean, error: string) => {
  return inactive ? theme.neutral100 : error ? theme.error25 : "transparent";
};

export const getBorderColor = (error: string) => {
  return error ? theme.error300 : theme.neutral300;
};

export const getHoverBorderColor = (error: string) => {
  return error ? theme.error500 : theme.neutral500;
};

export const getPadding = (size: SelectSizes) => {
  const paddingMap = {
    sm: theme.inputSmPadding,
    md: theme.inputMdPadding,
  };
  return paddingMap[size] || theme.inputSmPadding;
};

export const getTextColor = (value: string, inactive: boolean) => {
  return inactive || !value ? theme.textDisabled : theme.textPrimary;
};

export const getFocussedOutline = (error: string) => {
  return error ? theme.error200 : theme.primary300;
};
