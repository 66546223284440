export const trialIcons: { [key: string]: string } = {
  lightening: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial/lightening.svg`,
  cardIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial/add-card-2.png`,
  success: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial/add-card-success.svg`,
  addDetails: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial/add-details-btn.svg`,
  upgradeBtn: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial/upgrade-plan-btn.svg`,
  smallInstance: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial/small-instance.svg`,
  trialBg: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial/trial-upgrade-bg.svg`,
  lighteningWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial/lightningWhite.svg`,
  successBg: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial/success-bg.svg`,
  paygPlan: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial/payg-default.svg`,
  paygSelected: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial/payg-hover.svg`,
  capacityDefault: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial/capacity-default.svg`,
  capacityHover: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}trial/capacity-hover.svg`,
};
