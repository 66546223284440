import { routeNames } from "./routeNames";

const {
  home,
  login,
  signup,
  verifyEmail,
  unsupportedScreen,
  unsupportedBrowser,
  setupWorkspace,
  setupWarehouse,
  validateUser,
  provisioning,
  ide,
  ingestion,
  chooseWarehouse,
  connectWarehouse,
  resetPassword,
  resetPasswordRequest,
  resetPasswordSuccess,
  connector,
  team,
  settings,
  // apiKeySettings,
  connections,
  addConnection,
  previewConnection,
  editConnection,
  credentials,
  editCredentials,
  addCredentials,
  testConnection,
  duplicateConnection,
  profile,
  billing,
  jobs,
  addJobs,
  addJobsData,
  bi,
  environment,
  jobsRun,
  alerts,
  dbtDocs,
  dbtDocsSideBar,
  general,
} = routeNames;

type Route = {
  path: string;
  name: string;
  layout: string;
  isAuthenticatedRoute: boolean;
  pageHeader: boolean;
  isBackButton?: boolean;
  isSettingsLayout?: boolean;
  isHeaderBorder?: boolean;
};
type Routes = {
  [key: string]: Route;
};

export const ROUTES: Routes = {
  Home: {
    path: home,
    name: "Home",
    layout: "onboarding",
    isAuthenticatedRoute: false,
    pageHeader: false,
  },
  Login: {
    path: login,
    name: "Login",
    layout: "onboarding",
    isAuthenticatedRoute: false,
    pageHeader: false,
  },
  Signup: {
    path: signup,
    name: "Signup",
    layout: "onboarding",
    isAuthenticatedRoute: false,
    pageHeader: false,
  },
  VerifyEmail: {
    path: verifyEmail,
    name: "VerifyEmail",
    layout: "onboarding",
    isAuthenticatedRoute: false,
    pageHeader: false,
  },
  UnSupportedScreen: {
    path: unsupportedScreen,
    name: "UnSupportedScreen",
    layout: "onboarding",
    isAuthenticatedRoute: false,
    pageHeader: false,
  },
  UnSupportedBrowser: {
    path: unsupportedBrowser,
    name: "UnSupportedBrowser",
    layout: "onboarding",
    isAuthenticatedRoute: false,
    pageHeader: false,
  },
  SetupWorkspace: {
    path: setupWorkspace,
    name: "SetupWorkspace",
    layout: "onboarding",
    isAuthenticatedRoute: true,
    pageHeader: false,
  },
  SetupWarehouse: {
    path: setupWarehouse,
    name: "SetupWarehouse",
    layout: "onboarding",
    isAuthenticatedRoute: true,
    pageHeader: false,
  },
  ValidateUser: {
    path: validateUser,
    name: "ValidateUser",
    layout: "onboarding",
    isAuthenticatedRoute: false,
    pageHeader: false,
  },
  Provisioning: {
    path: provisioning,
    name: "Provisioning",
    layout: "onboarding",
    isAuthenticatedRoute: true,
    pageHeader: false,
  },
  IDE: {
    path: ide,
    name: "IDE",
    layout: "main",
    isAuthenticatedRoute: true,
    pageHeader: false,
  },
  Jobs: {
    path: jobs,
    name: "Jobs",
    layout: "main",
    isAuthenticatedRoute: true,
    pageHeader: true,
  },
  AddJobs: {
    path: addJobs,
    name: "New Job",
    layout: "main",
    isAuthenticatedRoute: true,
    pageHeader: true,
    isBackButton: true,
  },
  AddJobsData: {
    path: addJobsData,
    name: "New Job",
    layout: "main",
    isBackButton: true,
    isAuthenticatedRoute: true,
    pageHeader: true,
  },
  JobRuns: {
    path: jobsRun,
    name: "",
    layout: "main",
    isBackButton: true,
    isAuthenticatedRoute: true,
    pageHeader: true,
  },
  Ingestion: {
    path: ingestion,
    name: "Ingestion",
    layout: "main",
    isAuthenticatedRoute: true,
    pageHeader: true,
  },
  Settings: {
    path: settings,
    name: "Settings",
    layout: "settings",
    isAuthenticatedRoute: true,
    pageHeader: false,
  },
  // ApiKey: {
  //   path: apiKeySettings,
  //   name: "API Key",
  //   layout: "settings",
  //   isAuthenticatedRoute: true,
  //   pageHeader: true,
  //   isBackButton: false,
  //   isSettingsLayout: true,
  // },
  ChooseWarehouse: {
    path: chooseWarehouse,
    name: "ChooseWarehouse",
    layout: "onboarding",
    isAuthenticatedRoute: true,
    pageHeader: false,
  },
  ConnectWarehouse: {
    path: connectWarehouse,
    name: "ConnectWarehouse",
    layout: "onboarding",
    isAuthenticatedRoute: true,
    pageHeader: false,
  },
  resetPassword: {
    path: resetPassword,
    name: "ResetPassword",
    layout: "onboarding",
    isAuthenticatedRoute: false,
    pageHeader: false,
  },
  resetPasswordRequest: {
    path: resetPasswordRequest,
    name: "ResetPasswordRequest",
    layout: "onboarding",
    isAuthenticatedRoute: false,
    pageHeader: false,
  },
  resetSuccess: {
    path: resetPasswordSuccess,
    name: "ResetSuccess",
    layout: "onboarding",
    isAuthenticatedRoute: false,
    pageHeader: false,
  },
  connector: {
    path: connector,
    name: "Connector",
    layout: "main",
    isAuthenticatedRoute: true,
    pageHeader: true,
    isBackButton: true,
  },
  Team: {
    path: team,
    name: "Team",
    isAuthenticatedRoute: false,
    layout: "main",
    pageHeader: true,
    isBackButton: false,
    isHeaderBorder: false,
  },
  profile: {
    path: profile,
    name: "Profile",
    layout: "settings",
    isAuthenticatedRoute: true,
    pageHeader: true,
    isSettingsLayout: true,
    isBackButton: false,
  },
  Connections: {
    path: connections,
    name: "App connections",
    layout: "settings",
    isAuthenticatedRoute: true,
    pageHeader: true,
    isBackButton: false,
    isSettingsLayout: true,
  },
  NewConnection: {
    path: addConnection,
    name: "Setup new app connection",
    layout: "settings",
    isAuthenticatedRoute: true,
    pageHeader: true,
    isBackButton: true,
    isSettingsLayout: true,
  },
  PreviewConnection: {
    path: previewConnection,
    name: "Preview connection",
    layout: "settings",
    isAuthenticatedRoute: true,
    pageHeader: true,
    isBackButton: true,
    isSettingsLayout: true,
  },
  EditConnection: {
    path: editConnection,
    name: "Edit connection",
    layout: "settings",
    isAuthenticatedRoute: true,
    pageHeader: true,
    isBackButton: true,
    isSettingsLayout: true,
  },
  Environment: {
    path: environment,
    name: "",
    layout: "settings",
    isAuthenticatedRoute: true,
    pageHeader: false,
    isBackButton: false,
    isSettingsLayout: true,
  },
  Credentials: {
    path: credentials,
    name: "Credentials",
    layout: "settings",
    isAuthenticatedRoute: true,
    pageHeader: true,
    isBackButton: false,
    isSettingsLayout: true,
  },
  EditCredentials: {
    path: editCredentials,
    name: "Edit credentials",
    layout: "settings",
    isAuthenticatedRoute: true,
    pageHeader: true,
    isBackButton: true,
    isSettingsLayout: true,
  },
  AddCredentials: {
    path: addCredentials,
    name: "Add credentials",
    layout: "settings",
    isAuthenticatedRoute: true,
    pageHeader: true,
    isBackButton: true,
    isSettingsLayout: true,
  },
  TestConnection: {
    path: testConnection,
    name: "Test connection",
    layout: "settings",
    isAuthenticatedRoute: true,
    pageHeader: true,
    isBackButton: true,
    isSettingsLayout: true,
  },
  DuplicateConnection: {
    path: duplicateConnection,
    name: "Duplicate connection",
    layout: "settings",
    isAuthenticatedRoute: true,
    pageHeader: true,
    isBackButton: true,
    isSettingsLayout: true,
  },
  Billing: {
    path: billing,
    name: "Billing",
    layout: "settings",
    isAuthenticatedRoute: true,
    pageHeader: true,
    isBackButton: false,
    isSettingsLayout: true,
  },
  Architecture: {
    path: "/architecture",
    name: "Architecture",
    layout: "main",
    isAuthenticatedRoute: true,
    pageHeader: true,
    isBackButton: false,
  },
  BI: {
    path: bi,
    name: "Business Intelligence",
    layout: "main",
    isAuthenticatedRoute: true,
    pageHeader: false,
  },
  Utilization: {
    path: "/utilization",
    name: "Utilization",
    layout: "main",
    isAuthenticatedRoute: true,
    pageHeader: true,
    isBackButton: false,
  },
  Alerts: {
    path: alerts,
    name: "Alerts",
    layout: "settings",
    isAuthenticatedRoute: true,
    pageHeader: true,
    isBackButton: false,
    isSettingsLayout: true,
  },
  dbtDocs: {
    path: dbtDocs,
    name: "Docs",
    layout: "settings",
    isAuthenticatedRoute: true,
    pageHeader: true,
    isSettingsLayout: true,
    isBackButton: false,
  },
  dbtDocsSideBar: {
    path: dbtDocsSideBar,
    name: "Docs",
    layout: "main",
    isAuthenticatedRoute: true,
    pageHeader: true,
  },
  General: {
    path: general,
    name: "General",
    layout: "settings",
    isAuthenticatedRoute: true,
    pageHeader: true,
    isBackButton: false,
    isSettingsLayout: true,
  },
};
