import styled from "styled-components";
import theme from "../../../styles/theme";
import Typography from "@/uiCore/typography";

export const NavbarHeaderWrapper = styled("div")(() => ({}));

export const WorkspaceWrapper = styled("div")(() => ({
  background: theme.neutral800,
  padding: "8px",
  borderRadius: "4px",
  width: "160px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "8px",
  margin: "16px 0",
}));

export const WorkspaceName = styled(Typography.P2)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

export const WorkspaceInitials = styled("div")(() => ({
  background: theme.neutral700,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "6px",
  borderRadius: "4px",
  width: "32px",
  height: "32px",
}));

export const WorkspaceInitialWrap = styled("div")(() => ({
  margin: "16px 0",
}));

export const FlexWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
