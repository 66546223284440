import { Modal } from "antd";
import AntButton from "../button";
import theme from "../../styles/theme";
import Typography from "../typography";
import {
  DeleteConfirmation,
  FooterButtonWrapper,
  HeaderText,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PointerImage,
} from "./styled";
import React from "react";
import NextImage from "../image";
import { IngestionIcons } from "@/constants/icons/ingestion";
import { commonIcons } from "@/constants/icons/common";
import AntInput from "../input";

type AntModalProps = {
  title: string;
  children: React.ReactNode;
  onCancel: () => void;
  primaryButtonText?: string;
  primaryButtonAction?: () => void;
  primaryIcon?: string;
  secondaryButtonText?: string;
  secondaryButtonAction?: () => void;
  secondaryIcon?: string;
  tertiaryButtonText?: string;
  tertiaryButtonAction?: () => void;
  tertiaryIcon?: string;
  type?: "delete" | "info" | "success" | "back" | "";
  isLoading?: boolean;
  disabled?: boolean;
  maskClosable?: boolean;
  destroyOnClose?: boolean;
  headerCallBack?: () => void;
  closable?: boolean;
  noFooter?: boolean;
  rootClassName?: string; // Add this prop
  deleteInputText?: string;
  deletePermanent?: boolean;
};

const renderHeaderIcon = (type: any, headerCallBack?: any) => {
  if (type === "delete" || type === "info") {
    return (
      <NextImage
        src={
          type === "delete"
            ? IngestionIcons.delete_head
            : IngestionIcons.info_head
        }
        alt="icon"
        height={24}
        width={24}
      />
    );
  }
  if (type === "back")
    return (
      <PointerImage
        src={commonIcons.backButton}
        alt="back"
        height={24}
        width={24}
        onClick={() => headerCallBack!()}
      />
    );
  return null;
};

const renderButton = (
  text?: string,
  action?: any,
  icon?: string,
  variant?: any,
  isLoading?: boolean,
  disabled?: boolean,
  inputVal?: string,
  type?: "delete" | "info" | "success" | "back" | "",
  deleteInputText?: string,
  deletePermanent?: boolean,
) =>
  text && (
    <AntButton
      size="md"
      variant={variant}
      leftIcon={icon}
      handleClick={() => action!()}
      loading={isLoading}
      inactive={
        type === "delete"
          ? deletePermanent
            ? deleteInputText
              ? inputVal !== deleteInputText
              : inputVal !== "DELETE"
            : disabled
          : disabled
      }
    >
      {text}
    </AntButton>
  );

const renderHeader = (
  type: any,
  headerCallBack?: any,
  closable?: boolean,
  title?: string,
  onCancel?: any,
) => {
  return (
    <ModalHeader>
      <HeaderText>
        {renderHeaderIcon(type, headerCallBack || undefined)}
        <Typography.P1 weight={theme.fontWeightBold}>{title}</Typography.P1>
      </HeaderText>
      {closable || closable === undefined ? (
        <NextImage
          src={commonIcons.close}
          alt="close"
          onClick={onCancel}
          className="cursor-pointer"
          tooltip="Close"
        />
      ) : null}
    </ModalHeader>
  );
};

const AntModal: React.FC<AntModalProps> = ({
  title,
  children,
  onCancel,
  primaryButtonText,
  primaryButtonAction,
  primaryIcon,
  secondaryButtonText,
  secondaryButtonAction,
  secondaryIcon,
  tertiaryButtonText,
  tertiaryButtonAction,
  tertiaryIcon,
  type,
  isLoading,
  disabled,
  maskClosable,
  destroyOnClose,
  headerCallBack,
  closable,
  noFooter,
  rootClassName, // Add this prop
  deleteInputText,
  deletePermanent,
}) => {
  const [inputVal, setInputVal] = React.useState("");

  const renderDeleteIcon = () => {
    if (deletePermanent) {
      if (deleteInputText) {
        if (inputVal === deleteInputText) {
          return true;
        }
      } else if (inputVal === "DELETE") {
        return true;
      }
    } else {
      if (!disabled) {
        return true;
      }
    }
    return false;
  };

  const renderDeleteConfirmation = (
    type?: "delete" | "info" | "success" | "back" | "",
    deletePermanent?: boolean,
  ) => {
    return (
      type === "delete" &&
      deletePermanent && (
        <DeleteConfirmation>
          <Typography.P2 color={theme.textPrimary} margin="0 0 12px 0">
            {`Please type “${deleteInputText || "DELETE"}” in the box below to confirm.`}
          </Typography.P2>
          <AntInput
            placeholder={deleteInputText || "DELETE"}
            value={inputVal}
            onChange={(e) => setInputVal(e.target.value)}
          ></AntInput>
        </DeleteConfirmation>
      )
    );
  };
  return (
    <div>
      <Modal
        rootClassName={rootClassName || ""} // Use the rootClassName prop
        visible
        onCancel={onCancel}
        footer={null}
        closeIcon={null}
        centered
        destroyOnClose={destroyOnClose}
        maskClosable={maskClosable !== undefined ? maskClosable : true}
      >
        {renderHeader(type, headerCallBack, closable, title, onCancel)}
        <ModalContent>
          <>
            {children}
            {renderDeleteConfirmation(type, deletePermanent)}
          </>
        </ModalContent>
        {!noFooter && (
          <ModalFooter>
            <div>
              {renderButton(
                tertiaryButtonText,
                tertiaryButtonAction,
                tertiaryIcon,
                "ghost",
              )}
            </div>
            <FooterButtonWrapper>
              {renderButton(
                secondaryButtonText,
                secondaryButtonAction,
                secondaryIcon,
                "tertiary",
              )}
              {renderButton(
                primaryButtonText,
                primaryButtonAction,
                type === "delete"
                  ? renderDeleteIcon()
                    ? commonIcons.deleteWhite
                    : commonIcons.primaryIcon || commonIcons.trashDisable
                  : commonIcons[`${primaryIcon}`] || "",
                type === "delete"
                  ? "primary_destructive"
                  : type === "success"
                    ? "success"
                    : "primary",
                isLoading,
                disabled,
                inputVal,
                type,
                deleteInputText,
                deletePermanent,
              )}
            </FooterButtonWrapper>
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
};

export default AntModal;
