import React from "react";
import Lottie from "react-lottie-player";

export const FivexLoader = () => {
  return (
    <Lottie
      loop
      animationData={require("../../utils/5X-loader.json")} // Import your Lottie animation JSON
      play
    />
  );
};

export default FivexLoader;
