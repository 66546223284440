import theme from "@/styles/theme";
import Typography from "@/uiCore/typography";
import styled from "styled-components";
import { Row, Col, Switch } from "antd";
import { trialIcons } from "@/constants/icons/trial";

export const PlanTag = styled(Typography.P2)<{ color: boolean }>((props) => ({
  padding: "4px 8px",
  background: theme.primary100,
  color: props.color ? theme.primary600 : theme.textTertiary,
  margin: "0px 8px",
  borderRadius: "4px",
  fontWeight: "600",
}));

export const DiscountTag = styled(Typography.P3)(() => ({
  padding: "4px",
  background: theme.success500,
  borderRadius: "4px",
  border: `1px solid ${theme.success500}`,
  color: theme.textWhite,
  marginTop: "2px",
  width: "fit-content",
}));

export const PlanDescription = styled(Typography.P3)(() => ({
  color: "var(--text-tertiary)",
  fontWeight: "600",
}));

export const PlanValue = styled(Typography.P3)(() => ({
  color: "var(--Text-Primary)",
  fontWeight: "600",
  marginRight: "8px",
}));

export const StyledRow = styled(Row)(() => ({
  padding: "8px",
  border: "1px solid var(--neutral-40)",
}));

export const StyledCol = styled(Col)(() => ({
  padding: "8px",
  border: "1px solid var(--neutral-40)",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
}));

export const Warehouselabel = styled(Typography.P3)(() => ({
  color: "var(--text-tertiary)",
  fontWeight: "600",
}));

export const SubSectionWrapper = styled("div")<{
  paddingBottom?: string;
}>(({ paddingBottom }) => ({
  paddingBottom,
}));

export const WarehouseTag = styled(Typography.L1)<{
  backgroundColor?: string;
}>(({ backgroundColor }) => ({
  padding: "4px 8px",
  background: backgroundColor ? backgroundColor : theme.neutral100,
  marginLeft: "4px",
  borderRadius: "4px",
}));

export const StorageHeading = styled(Typography.P3)(() => ({
  padding: "8px",
}));

export const BiContainer = styled("div")(() => ({
  borderRadius: "8px",
  padding: "20px",
  display: "flex",
  justifyContent: "space-between",
  margin: "0px 0px 16px 0px",
  flexDirection: "column",
  cursor: "pointer",
  background: "var(--primary-100)",
  border: "1px solid var(--angular-hover, #A56BE7)", // Change to your desired hover border color
}));

export const BiTitle = styled("div")(() => ({
  color: "var(--text-primary)",

  /* Semibold/Paragraph 2 */
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "120%",
  letterSpacing: "-0.28px",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  display: "flex",
}));

export const BiSubtitle = styled("div")(() => ({
  color: "var(--text-primary)",
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "120%",
  letterSpacing: "-0.24px",
  padding: "5px 0px",
}));

export const BiIcon = styled("div")(() => ({
  marginRight: "12px",
  marginTop: "-10px",
}));

export const AntSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: var(--primary-500);
  }
`;

export const ImageWrapper = styled("div")(() => ({
  marginBottom: "20px",
}));

export const ModalWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  background: url(${trialIcons.successBg}) no-repeat top;
  background-size: cover;
  height: 400px;
`;

export const StyledTypo = styled(Typography.P3)`
  color: var(--Text-Secondary);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.28px;
  margin: 10px;
`;

export const EmptyStateDiv = styled("div")`
  width: 100%;
  height: 80px;
`;
