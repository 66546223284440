import React, { forwardRef, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkoutForm";
import { loadStripe } from "@stripe/stripe-js";

const CardPayment: React.FC<any> = forwardRef(
  ({ clientSecret, setIsLoading }, ref) => {
    const [stripe, setStripe] = React.useState<any>(null);
    useEffect(() => {
      setIsLoading(true);
      setStripe(loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY || ""));
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, []);
    const options: any = {
      // passing the client secret obtained in step 3
      clientSecret,
      // Fully customizable with appearance API.
      appearance: {
        theme: "stripe",
        variables: {
          colorPrimary: "#0570de",
          colorBackground: "#ffffff",
          colorText: "#30313d",
          colorDanger: "#df1b41",
          // eslint-disable-next-line quotes
          fontFamily: '"poppins", "Helvetica", "Arial", sans-serif',
          spacingUnit: "6px",
          borderRadius: "4px",
          fontSizeBase: "12px",
        },
        rules: {
          ".Input": {
            display: "block",
            border: "1.5px solid #E0E0E0",
          },
        },
      },
    };
    return (
      <Elements stripe={stripe} options={options}>
        <CheckoutForm ref={ref} />
      </Elements>
    );
  },
);
CardPayment.displayName = "CardPayment";

export default CardPayment;
