export const ArchitectureIcons: { [key: string]: string } = {
  aiLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/ai-logo.svg`,
  arrowCounterClockwise: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/ArrowCounterClockwise.svg`,
  arrowUpRight: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/arrow-up-right.svg`,
  businessIntelligenceLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/business-intelligence-logo.svg`,
  circle: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/circle.svg`,
  chartBar: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/chart-bar.svg`,
  cloud: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/cloud.svg`,
  fivexGreen: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/fivex-green.svg`,
  fivexPink: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/fivex-pink.svg`,
  fivexPurple: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/fivex-purple.svg`,
  ingestion: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/ingestion.svg`,
  magnifyingGlassMinus: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/MagnifyingGlassMinus.svg`,
  magnifyingGlassPlus: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/MagnifyingGlassPlus.svg`,
  productAnalyticsLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/product-analytics-logo.svg`,
  source: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/source.svg`,
  sourceLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/source-logo.svg`,
  storageLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/storage-logo.svg`,
  transformation: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/transformation.svg`,
  transformationLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/transformation-logo.svg`,
  transportLogo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}architecture/transport-logo.svg`,
};
