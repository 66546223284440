import React from "react";
import {
  FlexWrapper,
  NavbarHeaderWrapper,
  WorkspaceInitialWrap,
  WorkspaceInitials,
  WorkspaceWrapper,
  WorkspaceName,
} from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { setNavigation } from "../../../redux/userSlice";
import Typography from "../../../uiCore/typography";
import theme from "../../../styles/theme";
import { getInitials } from "../../../helpers/getInitials";
import NextImage from "@/uiCore/image";
import { commonIcons } from "@/constants/icons/common";
import { navigationIcons } from "@/constants/icons/navigations";
const NavbarHeader: React.FC = () => {
  const dispatch = useDispatch();
  const navbarStatus = useSelector(
    (state: { user: any }) => state.user.navbar,
  ).navbar;
  const navbarHoveredStatus = useSelector(
    (state: { user: any }) => state.user.navbarHovered,
  ).navbarHovered;
  const expandOrCollapse = () => {
    dispatch(setNavigation({ navbar: !navbarStatus }));
  };

  const companyData = useSelector(
    (state: { user: any }) => state.user.companyDetails,
  );
  const companyName = companyData?.companyDetails?.workspace?.name || "";
  return (
    <NavbarHeaderWrapper>
      {navbarStatus ? (
        <FlexWrapper>
          <div>
            <NextImage
              src={commonIcons.FivexLogoWhite}
              alt={"5X"}
              height={24}
              width={32}
            />
          </div>
          <NextImage
            src={navigationIcons.collapse}
            alt={"5X"}
            height={20}
            width={20}
            tooltip="Collapse"
            tooltipPosition="right"
            onClick={() => expandOrCollapse()}
            className="cursor-pointer"
          />
        </FlexWrapper>
      ) : navbarHoveredStatus ? (
        <NextImage
          src={navigationIcons.expand}
          alt={"Expand"}
          height={24}
          width={32}
          onClick={() => expandOrCollapse()}
          tooltip="Expand"
          tooltipPosition="right"
          className="cursor-pointer"
        />
      ) : (
        <NextImage
          src={commonIcons.FivexLogoWhite}
          alt={"5X"}
          height={24}
          width={32}
        />
      )}
      {navbarStatus ? (
        <WorkspaceWrapper>
          <WorkspaceInitials>
            <Typography.P2
              color={theme.textWhite}
              weight={theme.fontWeightSemibold}
            >
              {companyName && getInitials(companyName)}
            </Typography.P2>
          </WorkspaceInitials>
          <WorkspaceName
            color={theme.textWhite}
            weight={theme.fontWeightSemibold}
            title={companyName}
          >
            {companyName}
          </WorkspaceName>
        </WorkspaceWrapper>
      ) : (
        <WorkspaceInitialWrap>
          <WorkspaceInitials>
            <Typography.P2
              color={theme.textWhite}
              weight={theme.fontWeightSemibold}
            >
              {companyName && getInitials(companyName)}
            </Typography.P2>
          </WorkspaceInitials>
        </WorkspaceInitialWrap>
      )}
    </NavbarHeaderWrapper>
  );
};

export default NavbarHeader;
