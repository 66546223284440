export const SettingsIcons: { [key: string]: string } = {
  ArrowRight: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settings/arraow-right.svg`,
  activeDot: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settings/active-dot.svg`,
  addCredential: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settings/add-credntial.svg`,
  brokenDot: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settings/borken-dot.svg`,
  editProfilePic: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settings/edit-profile-pic.svg`,
  failedIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settings/failed-icon.svg`,
  passedIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settings/passed-icon.svg`,
  testSetup: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settings/test-setup.svg`,
  prodIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settings/prod.svg`,
  devIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settings/dev.svg`,
  warning: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settings/Warning.svg`,
  secure: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settings/secure.svg`,
  locked: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settings/locked.svg`,
  notSecure: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settings/notSecure.svg`,
  fivexHosted: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}settings/5X-hosted-dbtDocs.svg`,
};
