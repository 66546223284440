import styled from "styled-components";

export const TableWrapper = styled.div`
  display: flex;
  width: 100% !important;
  min-height: "500px";
  padding: 32px;
  background: red;
  color: "black";

  border-radius: 8px;
  border: 1px solid var(--Foundation-Neutrals-N40, #e3e4e6);
  box-shadow:
    0px 100px 80px 0px rgba(0, 0, 0, 0.07),
    0px 41.778px 33.422px 0px rgba(0, 0, 0, 0.05),
    0px 22.336px 17.869px 0px rgba(0, 0, 0, 0.04),
    0px 12.522px 10.017px 0px rgba(0, 0, 0, 0.04),
    0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.03),
    0px 2.767px 2.214px 0px rgba(0, 0, 0, 0.02);
`;

export const TableDiv = styled.div`
  height: "500px",
  min-height: "540px",
  max-height: "900px",
  width: "100%",
`;

export const AddConnectorBtnDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;
const SpanBase = styled("span")(() => ({
  borderRadius: "20px",
  height: "24px",
  display: "flex",
  fontSize: "14px",
  justifyContent: "center",
  alignItems: "center",
  padding: "2px 8px 2px 8px !important",
}));

export const ActiveSpan = styled(SpanBase)(({ theme }) => ({
  backgroundColor: theme.success50,
  color: theme.success700,
}));

export const BrokenSpan = styled(SpanBase)(({ theme }) => ({
  backgroundColor: theme.error50,
  color: theme.error700,
}));

export const InCompleteSpan = styled(SpanBase)(({ theme }) => ({
  color: theme.textTertiary,
  backgroundColor: theme.neutral100,
}));

export const SyncingSpan = styled(SpanBase)(({ theme }) => ({
  color: theme.textTertiary,
  backgroundColor: theme.textWhite,
}));

const CircleSpanBase = styled("span")(() => ({
  borderRadius: "50%",
  width: "8px",
  height: "8px",
  marginRight: "8px",
}));

export const CircleCompleteSpan = styled(CircleSpanBase)(({ theme }) => ({
  backgroundColor: theme.success500,
}));

export const CircleIncompleteSpan = styled(CircleSpanBase)(({ theme }) => ({
  backgroundColor: theme.neutral500,
}));

export const CircleBrokenSpan = styled(CircleSpanBase)(({ theme }) => ({
  backgroundColor: theme.error500,
}));

export const MainContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
}));
