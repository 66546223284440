import theme from "../../styles/theme";

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "ghost"
  | "primary_destructive"
  | "secondary_destructive"
  | "success";

export type ButtonSizes = "sm" | "md" | "lg";

export const getBackgroundColor = (
  variant: ButtonVariant,
  inactive: boolean,
) => {
  const colorMap = {
    primary: {
      active: theme.primary600,
      inactive: theme.primary200,
    },
    secondary: {
      active: theme.primary50,
      inactive: theme.primary25,
    },
    tertiary: {
      active: theme.textWhite,
      inactive: theme.neutral100,
    },
    ghost: {
      active: "transparent",
      inactive: theme.textWhite,
    },
    primary_destructive: {
      active: theme.error500,
      inactive: theme.error50,
    },
    secondary_destructive: {
      active: theme.error50,
      inactive: theme.error50,
    },
    success: {
      active: theme.success600,
      inactive: theme.success50,
    },
  };

  return (
    colorMap[variant]?.[inactive ? "inactive" : "active"] || theme.primary600
  );
};

export const getHoverBackgroundColor = (variant: ButtonVariant) => {
  const colorMap = {
    primary: theme.primary700,
    secondary: theme.textWhite,
    tertiary: theme.neutral100,
    ghost: theme.neutral100,
    primary_destructive: theme.error600,
    secondary_destructive: theme.error100,
    success: theme.success700,
  };

  return colorMap[variant] || theme.primary600;
};

export const getHoverBorderColor = (variant: ButtonVariant) => {
  const colorMap = {
    primary: theme.primary700,
    secondary: theme.primary700,
    tertiary: theme.neutral300,
    ghost: theme.primary700,
    primary_destructive: theme.error600,
    secondary_destructive: theme.error700,
    success: theme.success700,
  };

  return colorMap[variant] || theme.primary600;
};

export const getColor = (variant: ButtonVariant, inactive: boolean) => {
  const colorMap = {
    primary: {
      active: theme.textWhite,
      inactive: theme.primary400,
    },
    secondary: {
      active: theme.primary600,
      inactive: theme.primary300,
    },
    tertiary: {
      active: theme.neutral700,
      inactive: theme.neutral300,
    },
    ghost: {
      active: theme.neutral700,
      inactive: theme.neutral400,
    },
    primary_destructive: {
      active: theme.textWhite,
      inactive: theme.error300,
    },
    secondary_destructive: {
      active: theme.error600,
      inactive: theme.error300,
    },
    success: {
      active: theme.textWhite,
      inactive: theme.success300,
    },
  };

  return (
    colorMap[variant]?.[inactive ? "inactive" : "active"] || theme.textWhite
  );
};

export const getPadding = (size: ButtonSizes) => {
  const paddingMap = {
    sm: theme.buttonSmPadding,
    md: theme.buttonMdPadding,
    lg: theme.buttonLgPadding,
  };

  return paddingMap[size] || theme.buttonSmPadding;
};

export const getHeight = (size: ButtonSizes) => {
  const heightMap = {
    sm: theme.buttonSmHeight,
    md: theme.buttonMdHeight,
    lg: theme.buttonLgHeight,
  };

  return heightMap[size] || theme.buttonSmHeight;
};

export const getBorder = (variant: ButtonVariant, inactive: boolean) => {
  if (variant === "tertiary") {
    return "1px solid";
  } else if (variant === "primary" && inactive) {
    return "1px solid";
  } else {
    return null;
  }
};

export const getBorderColor = (variant: ButtonVariant, inactive: boolean) => {
  if (variant === "tertiary") {
    return theme.neutral300;
  } else if (variant === "primary" && inactive) {
    return theme.primary200;
  } else {
    return null;
  }
};

export const getFocussedOutline = (variant: ButtonVariant) => {
  const outlineMap = {
    primary: theme.primary300,
    secondary: theme.primary300,
    tertiary: theme.primary300,
    ghost: theme.primary300,
    primary_destructive: theme.error200,
    secondary_destructive: theme.error300,
    success: theme.success300,
  };

  return outlineMap[variant] || theme.primary600;
};
