import React, { useEffect, useState } from "react";
import {
  SidebarWrapper,
  LabelWrapper,
  StyledMenu,
  ImageWrapper,
} from "./styled";
import Typography from "@/uiCore/typography";
import { useRouter } from "next/router";
import theme from "@/styles/theme";
import { Menu, MenuProps } from "antd";
import { settingsMenu } from "@/constants/settings";
import { logout } from "@/helpers/authentication";
import NextImage from "@/uiCore/image";
import { ROLE_ACCESS } from "@/constants/rbac";
import { useSelector } from "react-redux";

type SidebarProps = {
  title: string;
};

const Sidebar = ({ title }: SidebarProps) => {
  const [navbarItems, setNavbarItems] = useState<any[]>([]);
  const companyDetails =
    useSelector((state: { user: any }) => state.user.companyDetails)
      ?.companyDetails || {};

  const { entitlements } = companyDetails || {};

  const getCurrentPathKey = (pathInput?: any) => {
    const path = pathInput ?? window.location.pathname;
    const key = navbarItems.find((item) => path?.includes(item.path))?.key;
    return key?.toString() || "1";
  };

  const [current, setCurrent] = useState(getCurrentPathKey());
  const router = useRouter();
  useEffect(() => {
    if (entitlements) {
      const menu = settingsMenu?.filter(
        (item) =>
          entitlements?.[`${item?.id?.toUpperCase()}`] !==
          ROLE_ACCESS.NO_ACCESS,
      );
      setNavbarItems(menu);
    }
  }, [entitlements]);

  useEffect(() => {
    setCurrent(getCurrentPathKey(router.asPath));
  }, [router.asPath, navbarItems]);

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
  };

  return (
    <SidebarWrapper>
      <Typography.P1 weight={theme.fontWeightSemibold} margin={"7px 0 0"}>
        {title}
      </Typography.P1>
      <StyledMenu
        onClick={onClick}
        selectedKeys={[current]}
        mode="vertical"
        className="custom-menu"
      >
        {navbarItems?.map((item) => (
          <Menu.Item
            key={item.key}
            onClick={() => {
              if (item.key === 10) {
                logout();
              } else {
                router.push(item.path);
              }
            }}
          >
            <LabelWrapper>
              <ImageWrapper>
                <NextImage
                  src={
                    item.key === Number(current) ? item.activeIcon : item.icon
                  }
                  alt={item.label}
                />
              </ImageWrapper>
              <Typography.P2
                margin={"3px 0 0"}
                color={
                  item.key === Number(current) && item.key !== 10
                    ? theme.primary600
                    : item?.color || theme.textPrimary
                }
              >
                {item.label}
              </Typography.P2>
            </LabelWrapper>
          </Menu.Item>
        ))}
      </StyledMenu>
    </SidebarWrapper>
  );
};

export default Sidebar;
