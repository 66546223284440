/* eslint-disable complexity */
import AntModal from "@/uiCore/modal";
import React, { useEffect, useState } from "react";
import Typography from "@/uiCore/typography";
import { emitter } from "@/utils/emitter";
import { emitterTypes } from "@/utils/types";
import { useSelector } from "react-redux";
import AntButton from "@/uiCore/button";
import { useIntercom } from "react-use-intercom";
import { FooterButtonWrapper, PlanContainer } from "./styled";
import theme from "@/styles/theme";
import Image from "@/uiCore/image";
import { trialIcons } from "@/constants/icons/trial";
import { navigationIcons } from "@/constants/icons/navigations";
import { TrialUpgrade } from "./trialUpgrade";
import { Message, useNotification } from "@/uiCore/message";
import { logout } from "@/helpers/authentication";
import usePreventBackNavigation from "@/helpers/preventBackward";
import styles from "./index.module.css";

const Trial: React.FC = () => {
  usePreventBackNavigation();
  const [visible, setVisible] = useState(false);
  const { showNewMessage } = useIntercom();
  const data = useSelector((state: { trial: any }) => state?.trial?.plans);
  const { showNotification, notification } = useNotification();
  const [isUpgradeView, setIsUpgradeView] = useState(false);
  const trialtitle = useSelector(
    (state: { trial: any }) => state?.trial?.title,
  );
  const trilExpired = useSelector(
    (state: { trial: any }) => state?.trial?.trialExpired,
  );
  const [title, setTitle] = useState(trialtitle);
  const [addCard, setAddCard] = useState(false);
  const [isAddCard, setIsAddCard] = useState(false);
  const [paygState, setPaygState] = useState(false);
  const [prepaidState, setPrepaidState] = useState(false);

  const closable = useSelector(
    (state: { trial: any }) => state?.trial?.closable,
  );

  const companyDetails = useSelector(
    (state: { user: any }) => state?.user?.companyDetails,
  )?.companyDetails;

  const trialActive =
    companyDetails?.workspace?.trialPeriod?.status === "ACTIVE";

  const handlePaidPlan = () => {
    setIsUpgradeView(true);
  };

  const handleBackBtn = () => {
    if (addCard) {
      setAddCard(false);
      setIsAddCard(false);
      setTitle("Upgrade to paid plan");
    } else {
      setAddCard(false);
      setIsUpgradeView(false);
      setTitle(trialtitle);
      setIsAddCard(false);
    }
  };

  const updateCall = (message: string) => {
    showNewMessage(message);
  };

  const toggleVisibility = (value: boolean) => {
    setVisible(value);
    if (!value) {
      setIsUpgradeView(false);
    }
  };
  const handleAddCard = () => {
    setAddCard(true);
    setTitle("Add a card");
  };
  useEffect(() => {
    emitter.on(emitterTypes.OPEN_TRIAL_MODAL, toggleVisibility);
    return () => {
      emitter.off(emitterTypes.OPEN_TRIAL_MODAL, toggleVisibility);
    };
  }, []);

  return (
    <>
      {visible && (
        <AntModal
          rootClassName={trilExpired ? styles["trial-wrapper"] : ""}
          noFooter
          title={
            isUpgradeView && !addCard
              ? "Upgrade to paid plan"
              : title || trialtitle || "Your trial period is over"
          }
          type={isUpgradeView ? "back" : "success"}
          onCancel={() => {
            if (closable) {
              handleBackBtn();
              emitter.emit(emitterTypes.OPEN_TRIAL_MODAL, false);
            }
          }}
          maskClosable={false}
          closable={closable}
          headerCallBack={isUpgradeView ? handleBackBtn : undefined}
        >
          {isUpgradeView ? (
            <TrialUpgrade
              data={data}
              cardCallBack={handleAddCard}
              setIsAddCard={setIsAddCard}
              isAddCard={isAddCard}
              handleSuccessCallBack={() => {
                showNotification("success", "Plan upgraded successfully");
                emitter.emit(emitterTypes.OPEN_TRIAL_MODAL, false);
                emitter.emit(emitterTypes.TRIAL_UPGRADE_SUCCESS, true);
              }}
            />
          ) : (
            <>
              <Typography.P2
                margin="8px 0 1rem 0"
                color={theme.textSecondary}
                weight={400}
              >
                {trialActive ? (
                  "Ready for more? Upgrade to a paid plan to keep your momentum going and unlock all the features 5X has to offer."
                ) : (
                  <>
                    Thank you for taking 5X for a spin, but your trial period
                    has ended.
                    <br />
                    Our support team would be happy to address any concerns.
                  </>
                )}
              </Typography.P2>
              <PlanContainer
                onClick={() => handlePaidPlan()}
                onMouseEnter={() => setPaygState(true)}
                onMouseLeave={() => setPaygState(false)}
              >
                <Image
                  src={
                    paygState ? trialIcons.paygSelected : trialIcons.paygPlan
                  }
                  alt="paid plan"
                  width={468}
                  height={84}
                />
              </PlanContainer>
              <PlanContainer
                onMouseEnter={() => setPrepaidState(true)}
                onMouseLeave={() => setPrepaidState(false)}
                onClick={() => {
                  updateCall(
                    "Hello! I would like to unlock more discounts with a capacity deal.",
                  );
                }}
              >
                <Image
                  src={
                    prepaidState
                      ? trialIcons.capacityHover
                      : trialIcons.capacityDefault
                  }
                  alt="capacity deal"
                  width={468}
                  height={84}
                />
              </PlanContainer>
              <FooterButtonWrapper key="footer">
                {!trialActive && (
                  <AntButton
                    fullWidth
                    size="md"
                    variant="secondary_destructive"
                    handleClick={() => {
                      emitter.emit(emitterTypes.OPEN_TRIAL_MODAL, false);
                      logout();
                    }}
                    leftIcon={navigationIcons.logout}
                  >
                    Log out
                  </AntButton>
                )}

                <AntButton
                  size="md"
                  fullWidth
                  variant="tertiary"
                  type="button"
                  handleClick={() => {
                    if (trialActive) {
                      setVisible(false);
                      emitter.emit(emitterTypes.OPEN_TRIAL_MODAL, false);
                    } else {
                      updateCall("Hello! I need help with my trial period.");
                    }
                  }}
                >
                  {trialActive ? "Upgrade later" : "Contact support"}
                </AntButton>
              </FooterButtonWrapper>
            </>
          )}
        </AntModal>
      )}
      {notification && (
        <Message
          status={notification.status as any}
          message={notification.message}
        />
      )}
    </>
  );
};

export default Trial;
