import React, { useEffect, useState } from "react";
import { TrialdaysWrapper } from "./styled";
import { Flex } from "@/styles/styled/styled";
import Typography from "@/uiCore/typography";
import theme from "@/styles/theme";
import { useSelector } from "react-redux";
import { getPercentage } from "@/helpers/trial";
import styles from "../index.module.css";
import NextImage from "@/uiCore/image";
import { navigationIcons } from "@/constants/icons/navigations";
import { emitter } from "@/utils/emitter";
import { emitterTypes } from "@/utils/types";
import Trial from "@/components/trial";
import { setTrial } from "@/redux/trial";
import { useDispatch } from "react-redux";
import { SuccessModal } from "@/components/trial/successModal";

const TrialComponent: React.FC = () => {
  const dispatch = useDispatch();
  const [workspace, setWorkspace] = useState<any>();
  const { arrowCircleRight, trial20, trialOver, trial2040, trial40 } =
    navigationIcons;
  const navbarStatus = useSelector(
    (state: { user: any }) => state.user.navbar,
  ).navbar;
  const navbarExpandOrCollapseComplete = useSelector(
    (state: { user: any }) => state.user.navbarExpandOrCollapseComplete,
  ).navbarExpandOrCollapseComplete;

  const companyData = useSelector(
    (state: { user: any }) => state.user.companyDetails,
  );

  useEffect(() => {
    if (companyData) {
      setWorkspace({ ...companyData?.companyDetails?.workspace?.trialPeriod });
    }
  }, [companyData]);

  const renderImage = () => {
    const { startDate, endDate, daysLeft } = workspace || {};
    const percentage = Number(getPercentage(startDate, endDate, daysLeft)); // Convert percentage to number
    if (!percentage) {
      return trialOver;
    } else if (percentage < 20) {
      return trial20;
    } else if (percentage >= 20 && percentage < 40) {
      return trial2040;
    } else if (percentage >= 40) {
      return trial40;
    }
  };

  return (
    <>
      {workspace?.status === "ACTIVE" && (
        <>
          <TrialdaysWrapper
            justifyContent="space-between"
            className="trial-days-wrapper"
            onClick={() => {
              emitter.emit(emitterTypes.OPEN_TRIAL_MODAL, true);
              dispatch(setTrial({ title: "Upgrade to a paid plan" }));
              dispatch(setTrial({ closable: true }));
            }}
          >
            <Flex wrap="nowrap">
              <NextImage src={renderImage() || ""} alt="trial" />
              {navbarStatus && navbarExpandOrCollapseComplete && (
                <Typography.P3
                  color={theme.textWhite}
                  margin="0 0 0 8px"
                  className={styles.days}
                >
                  {workspace?.daysLeft}{" "}
                  {workspace?.daysLeft > 1 ? "days left" : "day left"}
                </Typography.P3>
              )}
            </Flex>
            {navbarStatus && navbarExpandOrCollapseComplete && (
              <NextImage src={arrowCircleRight} alt="arrow circle" />
            )}
          </TrialdaysWrapper>
          <Trial />
          <SuccessModal />
        </>
      )}
    </>
  );
};

export default TrialComponent;
