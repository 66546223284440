import styled from "styled-components";
import theme from "../../styles/theme";
import { getBorderColor } from "./helpers";

type MessageWrapperProps = {
  status: string;
};

export const MessageWrapper = styled.div<MessageWrapperProps>((props) => ({
  border: `0.5px solid ${getBorderColor(props.status)}`,
  padding: "8px 12px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  maxWidth: "400px",
  width: "fit-content",
  gap: "8px",
  backgroundColor: theme.textWhite,
  zIndex: 10000,
  position: "fixed",
  top: 12,
  right: 12,
  borderRadius: "4px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "break-spaces",
}));
