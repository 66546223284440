import styled from "styled-components";
import theme from "../../styles/theme";

export const NavbarWrapper = styled("div")(() => ({
  padding: "20px",
  background: theme.neutral900,
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));
