export const MIN_WINDOW_SCREEN_SIZE = 1024;

export const fivetranRedirectionUrl = (
  token: string,
  params: boolean,
  edit: boolean,
) => {
  const redirectUrl = `${window.location.href}${edit ? `?edit=${edit}` : "?addConnector=success"}`;
  return `https://fivetran.com/connect-card/setup?redirect_uri=${redirectUrl}&auth=${token}`;
};
export const APP_TITLE = "5X";
