import { createSlice } from "@reduxjs/toolkit";

export const connectionSlice = createSlice({
  name: "connection",
  initialState: {
    connextionStep: 0,
  },
  reducers: {
    resetConnectionStep: (state) => {
      state.connextionStep = 0;
    },
    setConnectionStep: (state, action) => {
      state.connextionStep = action.payload;
    },
  },
});

export const { resetConnectionStep, setConnectionStep } =
  connectionSlice.actions;

export default connectionSlice.reducer;
