import React from "react";
import Image from "next/image";
import { Tooltip } from "antd";

type ImageProps = {
  id?: string;
  src: string;
  alt: string;
  width?: number;
  height?: number;
  layout?: "fixed" | "fill" | "intrinsic" | "responsive" | "raw";
  objectFit?: "contain" | "cover" | "fill" | "none" | "scale-down";
  className?: string;
  tooltip?: string;
  tooltipPosition?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";
  unoptimized?: boolean;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLImageElement>) => void;
};

const NextImage: React.FC<ImageProps> = ({
  id,
  src,
  alt,
  width = 16,
  height = 16,
  layout,
  objectFit,
  className,
  tooltip,
  tooltipPosition,
  onClick,
  unoptimized,
  style,
}) => {
  return (
    <Tooltip mouseEnterDelay={0.1} title={tooltip} placement={tooltipPosition}>
      {layout ? (
        <Image
          id={id}
          src={src}
          alt={alt}
          layout={layout}
          objectFit={objectFit}
          className={className}
          onClick={onClick}
          unoptimized={unoptimized}
          style={style}
        />
      ) : (
        <Image
          id={id}
          src={src}
          alt={alt}
          width={width}
          height={height}
          objectFit={objectFit}
          className={className}
          onClick={onClick}
          unoptimized={unoptimized}
          style={style}
        />
      )}
    </Tooltip>
  );
};

export default NextImage;
