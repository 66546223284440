import React from "react";
import Typography from "../../../uiCore/typography";
import theme from "../../../styles/theme";
import { NaviagationsWrapper } from "./styled";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import { useIntercom } from "react-use-intercom";
import NextImage from "@/uiCore/image";
import { navigationIcons } from "@/constants/icons/navigations";
import { ROLE_ACCESS } from "@/constants/rbac";

type NavigationItem = {
  name: string;
  path: string;
  icon: string;
  redirectTo: string;
  enabled: boolean;
  id: string;
};

type NavigationsProps = {
  navigationData?: Array<NavigationItem>;
};

const Navigations: React.FC<NavigationsProps> = ({ navigationData = [] }) => {
  const router = useRouter();
  const { boot } = useIntercom();
  const navbarStatus = useSelector(
    (state: { user: any }) => state.user.navbar,
  ).navbar;
  const currentPathname = window.location.pathname;
  const companyData = useSelector(
    (state: { user: any }) => state.user.companyDetails,
  )?.companyDetails;

  const { entitlements } = companyData || { entitlements: {} };

  const updateCall = () => {
    boot({
      name: companyData.name,
    });
  };
  return (
    <>
      {navigationData.map(
        (navigation: NavigationItem, index: number) =>
          entitlements[`${navigation?.id?.toUpperCase()}`] !==
            ROLE_ACCESS.NO_ACCESS && (
            <NaviagationsWrapper
              id={navigation.id}
              key={index}
              active={currentPathname.startsWith(navigation.redirectTo)}
              onClick={() =>
                navigation.enabled && navigation.redirectTo === "/help"
                  ? updateCall()
                  : router.push(navigation.redirectTo)
              }
              enabled={navigation.enabled}
            >
              <NextImage
                height={20}
                width={20}
                src={
                  navigationIcons[
                    navigation.icon as keyof typeof navigationIcons
                  ]
                }
                alt={navigation.icon}
                tooltip={!navbarStatus ? navigation.name : ""}
                tooltipPosition="right"
              />
              {navbarStatus && (
                <Typography.P2 color={theme.textWhite}>
                  {navigation.name}
                </Typography.P2>
              )}
            </NaviagationsWrapper>
          ),
      )}
    </>
  );
};

export default Navigations;
