import { createSlice } from "@reduxjs/toolkit";

export const ApiKeyStore = createSlice({
  name: "apiKey",
  initialState: {
    isModalOpen: false,
    keyValue: "",
    showKeyModal: "",
    deleteKeyId: "",
    keyDeleted: "",
    apiKeyLength: [],
  },
  reducers: {
    setIsModalOpen: (state, action) => {
      if ("isModalOpen" in action.payload) {
        state.isModalOpen = action.payload;
      }
    },
    setKeyValue: (state, action) => {
      if ("keyValue" in action.payload) state.keyValue = action.payload;
    },
    setShowKeyModal: (state, action) => {
      if ("showKeyModal" in action.payload) state.showKeyModal = action.payload;
    },
    setDeleteKeyId: (state, action) => {
      if ("deleteKeyId" in action.payload) state.deleteKeyId = action.payload;
    },
    setKeyDeleted: (state, action) => {
      if ("keyDeleted" in action.payload) state.keyDeleted = action.payload;
    },
    setApiKeyLength: (state, action) => {
      if ("apiKeyLength" in action.payload) state.apiKeyLength = action.payload;
    },
  },
});

export const {
  setIsModalOpen,
  setKeyValue,
  setDeleteKeyId,
  setShowKeyModal,
  setKeyDeleted,
  setApiKeyLength,
} = ApiKeyStore.actions;
export default ApiKeyStore.reducer;
