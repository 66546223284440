import AntButton from "@/uiCore/button";
import Image from "next/image";
import Typography from "@/uiCore/typography";
import { Flex } from "@/styles/styled/styled";
import { trialIcons } from "@/constants/icons/trial";
import { ModalWrapper, StyledTypo } from "./trialUpgrade/styled";
import { useRouter } from "next/router";
import { emitter } from "@/utils/emitter";
import { emitterTypes } from "@/utils/types";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";

export const SuccessModal: React.FC = () => {
  const router = useRouter();
  const [visible, setVisible] = useState(false);

  const getUserData = () => {
    emitter.emit(emitterTypes.OPEN_TRIAL_MODAL, false);
    emitter.emit(emitterTypes.TRIAL_UPGRADED, true);
    emitter.emit(emitterTypes.TRIAL_UPGRADE_SUCCESS, false);
    router.push("/ide");
  };
  const StyledWidth = styled.div`
    width: 272px;
  `;

  const handleVisibility = (val: boolean) => {
    setVisible(val);
  };

  useEffect(() => {
    emitter.on(emitterTypes.TRIAL_UPGRADE_SUCCESS, handleVisibility);
    return () => {
      emitter.off(emitterTypes.TRIAL_UPGRADE_SUCCESS, handleVisibility);
    };
  }, []);

  return (
    <Modal
      title={null}
      visible={visible}
      footer={null}
      closable={false}
      centered
      maskClosable={false}
    >
      <ModalWrapper>
        <Image
          src={trialIcons.success}
          alt="success"
          width={100}
          height={100}
          className="success-image"
        />
        <Typography.H2 weight={"700"} margin="10px 0">
          Wuhoo! You&apos;re all set
        </Typography.H2>
        <StyledWidth>
          <StyledTypo>
            You&apos;ve upgraded to a paid plan. Get ready to harness the full
            power of data and leverage the full potential of 5X!
          </StyledTypo>
        </StyledWidth>
        <Flex justifyContent="center">
          <AntButton
            size="md"
            variant="primary"
            handleClick={() => {
              getUserData();
            }}
          >
            Let&apos;s go
          </AntButton>
        </Flex>
      </ModalWrapper>
    </Modal>
  );
};
