const auth = "/auth/v1";
const signupEmail = "/signup/email";
export const workspace = "/workspaces/v1";
export const vendor = "/data-vendors/v1";
export const stackExecution = "/stack-executions/v1";
export const users = "/users/v1";
export const ingestion = "/data-pipelines/v1";
export const connections = "/connections/v1";
export const credentials = "/credentials/v1";
export const snowflakeEndpoint = "/snowflake-integration/v1";
export const worksheets = "/worksheets/v1";
const billing = "/dcc/v1";
export const ide = "/ide/v1";
export const tables = "/tables/v1";
export const git = "/git/v1/repositories";
const gitV1 = "/git/v1";
export const bi = "/business-intelligence/v1";
export const jobs = "/workflows/v1";
export const environment = "/environments/v1";
export const environmentVariables = "/environment-variables/v1";
export const alert = "/alert/v1";
export const dbtDocs = "/dbt-docs/v1";
export const validations = "/validations/v1";
export const apiEndpoints = {
  validate: `${workspace}/validate`,
  signupFinish: `${auth}${signupEmail}/finish`,
  dataWarehouse: `${vendor}?category=DATA_WAREHOUSE`,
  configurations: "/configurations",
  signup: `${auth}${signupEmail}`,
  googleAuth: `${auth}/signup/google-auth`,
  verify: `${auth}${signupEmail}/verify`,
  stackSetupStatus: `${workspace}/status`,
  signIn: `${auth}/signin`,
  resetInit: `${auth}/reset-password/init`,
  reset: `${auth}/reset-password`,
  userData: `${users}/me`,
  connectionTest: "/connection-tests/v1",
  apiKeys: "/api-keys/v1",
  checkLink: `${auth}/reset-password/check`,
  connectors: `${ingestion}/connectors`,
  connectorTypes: `${ingestion}/connector-types`,
  destinations: `${ingestion}/destinations`,
  configMetadata: "/config-metadata",
  fivetranSetup: "/setup",
  connectorActions: `${ingestion}/connectors`,
  syncs: "/syncs",
  connectorDetails: `${ingestion}/connectors`,
  schemas: "/schemas",
  reloads: "/reloads",
  reloadWithId: `${ingestion}/reloads/`,
  pollingReloads: `${ingestion}/reloads`,
  changePassword: `${users}/me/reset-password`,
  uploadPhoto: `${users}/me/profile-image`,
  connectionsMetadata: `${connections}/metadata`,
  credentialsMetadata: `${credentials}/metadata`,
  connectionPolling: `${connections}/executions`,
  dataSource: `${ingestion}/configured-data-sources`,
  proxyImages: "utilities/v1/image-proxy?url=raw_image_url",
  fetchSnowflakeRoles: `${snowflakeEndpoint}/roles`,
  fetchSnowflakeWarehouses: `${snowflakeEndpoint}/warehouses`,
  fetchSnowflakeTables: `${snowflakeEndpoint}/tables`,
  renameWorksheet: `${worksheets}/rename`,
  fetchWorksheetContent: `${worksheets}/contents`,
  deleteWorksheet: `${worksheets}/delete`,
  workspaceMetadata: `${workspace}/metadata`,
  settings: `${workspace}/settings`,
  plan: `${billing}/plans`,
  billingDetails: `${billing}/billing-details`,
  paymentMethods: `${billing}/payment-methods`,
  invoices: `${billing}/invoices`,
  deactivateUser: "/users/v1",
  roles: "/data-vendors/v1/configured",
  externalUsers: "/external-users/v1",
  fiveXUsers: "/users/v1?externalAssociationSupportFilter=snowflake",
  heartbeat: `${ide}/heartbeat`,
  restart: `${ide}/restart`,
  executions: `${ide}/executions`,
  tabelMetadata: `${tables}/metadata`,
  billingInvoice: "/billing/invoices/v1",
  download: "/download",
  gitClone: `${gitV1}/clone`,
  gitBranches: `${gitV1}/branches`,
  gitCheckout: `${gitV1}/checkout`,
  gitListFiles: `${gitV1}/list-files`,
  gitCreateFile: `${gitV1}/create-file`,
  gitRenameFile: `${gitV1}/rename-file`,
  gitDeleteFile: `${gitV1}/delete-file`,
  gitViewFile: `${gitV1}/view-file`,
  gitCommitAndSync: `${gitV1}/commit-and-sync`,
  gitRevertFile: `${gitV1}/revert-file`,
  gitResetHard: `${gitV1}/reset-hard`,
  gitPull: `${gitV1}/pull`,
  gitMerge: `${gitV1}/merge`,
  supersetLogin: `${bi}/login`,
  jobConfigurations: `${jobs}/configurations`,
  jobDependentFields: `${jobs}/configurations/dependant-fields/_query`,
  jobs: `${jobs}`,
  role: "/roles/v1?includeEntitlements=true",
  utilizationUsage: "/utilization/v1/vendor-usages/_query",
  aggregatedSpend: "/utilization/v1/aggregated-usages/_query",
  environmentData: `${environment}`,
  environmentVariablesData: `${environmentVariables}`,
  dbtCommandExecution: `${ide}/dbt/commands`,
  dbtCompile: `${ide}/dbt/compile-executions`,
  dbtPreview: `${ide}/dbt/preview-executions`,
  dbtCommandLogs: `${ide}/dbt/command-logs`,
  dbtParseExecutions: `${ide}/dbt/parse-executions`,
  dccPlans: "/dcc/v1/plans",
  dccPlanUpgrade: "/dcc/v1/customers/plan-upgrade",
  prerequisiteScript: `${connections}/scripts/file`,
  alertAppIntegration: `${alert}/app-integration`,
  alertChannels: `${alert}/channels`,
  alertPreferences: `${alert}/preferences`,
  slackChannels: `${alert}/slack/channels`,
  lineageExecutions: `${ide}/dbt/lineage-executions`,
  lineageMetadata: `${ide}/dbt/lineage-metadata-executions`,
  dbtDocsData: `${dbtDocs}`,
  getWorkflowNodes: "/git/v1/repositories",
  changeDefaultDbtDoc: `${git}`,
  deleteBranch: `${gitV1}/delete-branch`,
};
