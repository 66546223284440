export const teamsIcons: { [key: string]: string } = {
  accessInfo: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams/teams-access-info.svg`,
  accessWarning: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams/teams-access-warning.svg`,
  editAlert: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams/edit-alert.svg`,
  greenCheck: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams/green-check.svg`,
  infoRed: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams/info-red.svg`,
  invite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams/invitation-pending.svg`,
  outsideFiveX: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams/outside-5x-placeholder.svg`,
  readOnly: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams/read-only.svg`,
  redCross: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams/red-cross.svg`,
  teamsBlack: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams/teams-black.svg`,
  teamsNodata: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams/teams-nodata.svg`,
  userPlus: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams/user-plus.svg`,
  reInvite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}teams/reinvite.svg`,
};
