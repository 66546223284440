import React from "react";
import Sidebar from "../components/secondaryNavbar";
import { Header } from "../components/header";
import styles from "./mainLayout.module.css";

type SettingsLayoutProps = {
  children: React.ReactNode;
  pageHeader?: boolean;
  isBackButton?: boolean;
  headerTitle?: string;
};

export const SettingsLayout: React.FC<SettingsLayoutProps> = ({
  children,
  pageHeader,
  isBackButton,
  headerTitle,
}) => {
  return (
    <div className={styles["settings-layout-container"]}>
      <div className={styles.sidebarWrapper}>
        <Sidebar title="Settings" />
      </div>
      <div className={styles.ContentWidth}>
        <div className={styles["content-container"]}>
          {pageHeader && (
            <Header
              headerTitle={headerTitle || ""}
              isBackButton={isBackButton}
            />
          )}
          <div className={styles.childrenWrapper}>{children}</div>
        </div>
      </div>
    </div>
  );
};
