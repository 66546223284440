import React, { useEffect, useState, useRef } from "react";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import "react-reflex/styles.css";
import Navbar from "../components/navbar";
import { useDispatch, useSelector } from "react-redux";
import styles from "./mainLayout.module.css";
import { Header } from "../components/header";
import { ROUTES } from "../constants/routes";
import { useRouter } from "next/router";
import { SettingsLayout } from "./settingsLayout";
import { apiEndpoints } from "@/constants/api";
import { getFetcher, postFetcher } from "@/services/connections";
import {
  setCompanyDetails,
  setRolesTooltipData,
  setNavigationExpandOrCollapseComplete,
  setDbtDocsOnNavbar,
} from "@/redux/userSlice";
import { setBIInstance } from "@/helpers/cookieStorage";
import { emitter } from "@/utils/emitter";
import { emitterTypes } from "@/utils/types";
import { setTrial } from "@/redux/trial";
import { SuccessModal } from "@/components/trial/successModal";
import Trial from "@/components/trial";
import useSWR from "swr";
import { heapIdentity } from "@/utils/helper";

type MainLayoutProps = {
  children: React.ReactNode;
  isBackButton?: boolean;
  isSettingsLayout?: boolean;
  pageHeader?: boolean;
  headerTitle?: string;
};

export const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  isBackButton,
  isSettingsLayout,
  pageHeader,
  headerTitle,
}) => {
  const navbarStatus = useSelector(
    (state: { user: any }) => state.user.navbar,
  ).navbar;
  const router = useRouter();
  const [size, setSize] = useState<number>(200);
  const dispatch = useDispatch();
  const { userData, role } = apiEndpoints;

  const { data, isLoading, mutate } = useSWR(userData);
  const [initialRender, setInitialRender] = useState(true);
  const { data: roleData, mutate: roleDataMutate } = useSWR(role);
  const companyData = useSelector(
    (state: { user: any }) => state.user.companyDetails,
  );
  const rolesData = useSelector((state: { user: any }) => state.user.rolesData);
  const plans = useSelector((state: { trial: any }) => state?.trial?.plans);
  const plansFetched = useRef(false);

  useEffect(() => {
    if (data) {
      dispatch(setCompanyDetails({ companyDetails: data }));
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (roleData?.data) {
      dispatch(setRolesTooltipData({ rolesTooltipData: roleData.data }));
    }
  }, [roleData, isLoading]);

  const getUserData = async () => {
    const res = await getFetcher(apiEndpoints.userData);
    if (res?.status === "SUCCESS") {
      heapIdentity(
        res?.data?.name,
        res?.data?.email,
        res?.data?.workspace?.name,
      );
      mutate(res?.data);
      const route = res.data?.workspace?.settings?.defaultLandingPage;
      sessionStorage.setItem("defaultLandingPage", route);
      const showDocsOnNavbar = res?.data?.workspace?.settings?.showDocsInNavbar;
      dispatch(setDbtDocsOnNavbar({ showDocsOnNavbar: showDocsOnNavbar }));
    }
    if (res?.data?.workspace?.trialPeriod?.status === "EXPIRED") {
      emitter.emit(emitterTypes.OPEN_TRIAL_MODAL, true);
      dispatch(
        setTrial({
          title: "Your trial period is over",
          closable: false,
          trialExpired: true,
        }),
      );
    }
  };

  const getRolesData = async () => {
    const res = await getFetcher(role);
    roleDataMutate(res);
  };

  const getPlans = async () => {
    if (!plansFetched.current) {
      const res = await getFetcher(
        `${apiEndpoints.dccPlans}?includePlanPricing=true`,
      );
      if (res?.status === "SUCCESS") {
        dispatch(setTrial({ plans: res?.data }));
        plansFetched.current = true;
      }
    }
  };

  useEffect(() => {
    if (!companyData) {
      getUserData();
    }
  }, []);

  useEffect(() => {
    if (!rolesData) {
      getRolesData();
    }
    if (!plans) {
      getPlans();
    }
  }, []);

  useEffect(() => {
    const getBiStatus = async (intervalId: NodeJS.Timeout) => {
      const res = await postFetcher(apiEndpoints.supersetLogin, {});
      if (res?.status === "SUCCESS") {
        if (res?.data.loginStatus === "SUCCESS") {
          clearInterval(intervalId);
          setBIInstance(res.data.loginUrl);
        } else if (res?.data.loginStatus === "PROVISIONING_IN_PROGRESS") {
          setBIInstance("PROVISIONING_IN_PROGRESS");
        } else {
          setBIInstance("NOT_PROVISIONED");
          clearInterval(intervalId);
        }
      } else if (res?.data?.loginStatus === "PROVISIONING_IN_PROGRESS") {
        setBIInstance("PROVISIONING_IN_PROGRESS");
      } else {
        setBIInstance("NOT_PROVISIONED");
        clearInterval(intervalId);
      }
    };

    const intervalId = setInterval(() => {
      getBiStatus(intervalId);
    }, 20000);

    // Call it immediately
    getBiStatus(intervalId);

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    emitter.on(emitterTypes.TRIAL_UPGRADED, getUserData);
    return () => {
      emitter.off(emitterTypes.TRIAL_UPGRADED, getUserData);
    };
  }, []);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      return;
    }
    if (navbarStatus) {
      onMaximizeClicked();
    } else {
      onMinimizeClicked();
    }
  }, [navbarStatus]);

  const onMinimizeClicked = () => {
    const leftPaneElement = document.querySelector(".left-pane");
    const currentSize = leftPaneElement ? leftPaneElement?.clientWidth : 0;
    const update = (sizeq: number) => {
      return Promise.resolve(setSize(sizeq < 72 ? 72 : sizeq));
    };

    const done = (from: number, to: number) => {
      return from < to;
    };

    animate(currentSize, 72, -10, done, update);
  };

  const onMaximizeClicked = () => {
    const leftPaneElement = document.querySelector(".left-pane");
    const currentSize = leftPaneElement ? leftPaneElement?.clientWidth : 0;

    const update = (sizeq: number) => {
      return Promise.resolve(setSize(sizeq));
    };

    const done = (from: number, to: number) => {
      return from > to;
    };

    animate(currentSize, 200, 10, done, update);
  };

  const animate = (
    start: number,
    end: number,
    step: number,
    done: (start: number, end: number) => boolean,
    fn: (value: number) => Promise<void>,
  ) => {
    const stepFn = () => {
      if (!done(start, end)) {
        fn((start += step)).then(() => {
          window.requestAnimationFrame(stepFn);
        });
      } else {
        dispatch(
          setNavigationExpandOrCollapseComplete({
            navbarExpandOrCollapseComplete: navbarStatus,
          }),
        );
      }
    };
    stepFn();
  };

  const isHeaderPresent = Object.keys(ROUTES).some((key) => {
    //eslint-disable-next-line
    return ROUTES[key].path === router.pathname && ROUTES[key].pageHeader;
  });

  return (
    <>
      <div className={styles.splitter}>
        <ReflexContainer orientation="vertical">
          <ReflexElement
            className="left-pane"
            size={size}
            minSize={72}
            maxSize={200}
          >
            <Navbar />
          </ReflexElement>
          <ReflexSplitter className="splitter-main-layout" />
          <ReflexElement className="right-pane">
            <div className="pane-content">
              {!isSettingsLayout && isHeaderPresent && (
                <Header isBackButton={isBackButton} />
              )}
              {isSettingsLayout ? (
                <SettingsLayout
                  isBackButton={isBackButton}
                  pageHeader={pageHeader}
                  headerTitle={headerTitle}
                >
                  {children}
                </SettingsLayout>
              ) : (
                <div>{children}</div>
              )}
            </div>
          </ReflexElement>
        </ReflexContainer>
      </div>
      <Trial />
      <SuccessModal />
    </>
  );
};
